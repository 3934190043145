import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./Preferences.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

export default function Version() {
  const { t, i18n } = useTranslation();
  const releaseNotes = [
    {
      version: "25.1",
      date: "12.02.25",
      description:
        "Status der Patienten; Anpassungen diverser Layouts; Neue Programmvorschau; Einheit übernehmen bei Programmvorlage",
    },
    {
      version: "24.3.2",
      date: "03.09.24",
      description: "Neuer Menüpunkt Materialien",
    },
    {
      version: "24.3.1",
      date: "14.05.24",
      description:
        "Performanceoptimierung: Bei Programmvoralgen bzw.Zuweisungen werden erst mit öffnen des Modal die Daten geladen",
    },
    {
      version: "24.3",
      date: "03.04.24",
      description:
        "Anpassung Login Mail; Website Registrierung -> Datenanpassung; Uploadsberechtigung",
    },
    {
      version: "24.2",
      date: "29.02.24",
      description:
        "Simple Analyse der Programm und Patientendaten; Druck Italienisch; Neue Doku für Patient und Therapeut",
    },
    {
      version: "24.1",
      date: "01.02.24",
      description:
        "Profilbildupload für Patienten; Bildupload für Programme; Fehlerbehebung bei Programmbearbeitungsvorschau des Beschreibungstext; Schnittstelle für treatsoft zum Übungsupload",
    },
    {
      version: "23.0.10",
      date: "13.12.23",
      description:
        "Programmvorschau für Desktop und Mobile angepasst; Sortierung Vorlagen;Safari Playbutton Anpassung - wird nun kleiner angezeigt",
    },
    {
      version: "23.0.9",
      date: "08.11.23",
      description:
        "Menü-hover um mehr Platz zu sparen;ReplyTo Mailanpassung;Anpassung Schnittstelle; Scrolling Layout",
    },
    {
      version: "23.0.8",
      date: "15.09.23",
      description:
        "Vorlagen übergreifend bei Mandanten; Hinweis ob Druck, Veröffentlicht etc;Spracheauswahl Fehlerbehebung;MaxUser Funktion; Anpassung Mailtexte; Patientenname bei Druck (optional);Option Mail an Therapeut falls Patient rückmeldet;Diverse Anpassungen",
    },
    {
      version: "23.0.7",
      date: "19.06.23",
      description:
        "Anpassung sophyapp Übungungskatalog; Doppelnamen bei Patienten werden angezeigt; Scrolling und Resize optimiert; Diverse Bugfixes",
    },
    {
      version: "23.0.6",
      date: "03.05.23",
      description:
        "Standortgruppierung für Mandanten; Zuordnung Standort für Patient; Vergrößerung der Programmvorschau; Menü/Patienten bei kleinerer Auflösung responsive; Button Eigenschaften auf alle übertragen; Nur eigen Übungen anzeigen filter; Diverse Bugfixes",
    },
    {
      version: "23.0.5.1",
      date: "13.04.23",
      description:
        "Leere Übungsattribute werden automatisch mit Standardwerten versehen;Optimierung Vorschau;Es können nun auch Bilder als Übung hochgeladen werden",
    },
    {
      version: "23.0.5",
      date: "12.04.23",
      description:
        "Vergrößerte Ansicht des Vorschauprogram (Optimierung folgt);Diverse Bugfixes wie 'scrolling - Bearbeiten Programm'",
    },
    {
      version: "23.0.4",
      date: "29.03.23",
      description:
        "Druckanpassung bei Pause/Serien Werten 0; Aktive/Inaktive Patienten; Filtermöglichkeiten Patientenübersicht;Sortiermöglichkeit Patientenübersicht; Fixieren Header Programmerstellen; Gewicht in Einstellungen; Wiederverwendung abgelaufener Programme; Diverse Bugfixes",
    },
    {
      version: "23.0.3",
      date: "21.03.23",
      description:
        "Anpassung Pagination bei Filter; Top Scrolling; Patient löschen; Verknüpfung treatsoft-id; Notizen; Programm per Mail versenden; Programm wiederverwenden; Dynamische Ansicht bei Programmübersicht; Ausblenden Programmvorschau;Diverse Bugfixes",
    },
    {
      version: "23.0.2",
      date: "14.03.23",
      description:
        "Pagination für die Mail-Archive/Übungen hinzugefügt; Performance verbessert bei Patienten- und Übungsübersicht; Bugfixes",
    },
    {
      version: "23.0.1",
      date: "01.03.23",
      description:
        "Release mit Standard-Features aus der Version 22 mit neu überarbeitetem Design",
    },
  ];
  return (
    <TableContainer>
      <Table className="version-table" aria-label="Release Notes">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: "16px" }}>
              <b>Version</b>
            </TableCell>
            <TableCell style={{ fontSize: "16px" }}>
              <b>{t("others.date")}</b>
            </TableCell>
            <TableCell style={{ fontSize: "16px" }}>
              <b>{t("others.description")}</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {releaseNotes.map((note) => (
            <TableRow key={note.version}>
              <TableCell component="th" scope="row">
                {note.version}
              </TableCell>
              <TableCell style={{ fontSize: "16px" }}>{note.date}</TableCell>
              <TableCell style={{ fontSize: "16px" }}>
                {" "}
                <ul>
                  {note.description.split(";").map((item) => (
                    <li key={item.trim()}>{item.trim()}</li>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
