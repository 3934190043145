import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  height: 400,
  marginTop: theme.spacing(2),
}));

const metrics = [
  { value: "total_patients", label: "Anzahl Patienten" },
  { value: "total_programs", label: "Anzahl Programme" },
  { value: "total_sessions", label: "Anzahl Sitzungen" },
  { value: "total_downloads", label: "Anzahl Heruntergeladene Programme" },
];

const formatDate = (year, month) => {
  // Handle 'all' cases
  if (year === "all") return "Alle Jahre";
  if (month === "all") return `${year} (Alle Monate)`;

  // Create a date string (using first day of month)
  const date = new Date(parseInt(year), parseInt(month) - 1, 1);
  return date.toLocaleDateString("de-DE", { year: "numeric", month: "long" });
};

export const ChartView = ({ data, yearFilter, monthFilter }) => {
  const [selectedMetric, setSelectedMetric] = useState("total_patients");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const users = useMemo(() => {
    return Array.from(new Set(data[0].map((item) => item.email)));
  }, [data]);

  const filteredData = useMemo(() => {
    let filtered = data[0];

    if (selectedUsers.length > 0) {
      filtered = filtered.filter((item) => selectedUsers.includes(item.email));
    }

    // Create a formatted date label using the year and month filters
    const dateLabel = formatDate(yearFilter, monthFilter);

    return filtered.map((item) => ({
      date: dateLabel,
      name: item.email,
      [selectedMetric]: item[selectedMetric],
    }));
  }, [data, selectedUsers, selectedMetric, yearFilter, monthFilter]);

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedUsers(event.target.value);
  };

  const getBarColor = (metric) => {
    const colors = {
      total_patients: "#8884d8",
      total_programs: "#82ca9d",
      total_sessions: "#ffc658",
      total_downloads: "#ff7300",
    };
    return colors[metric] || "#8884d8";
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          <p>
            <strong>{label}</strong>
          </p>
          {payload.map((pld, index) => (
            <p key={index} style={{ color: pld.color }}>
              {pld.name}: {pld.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <StyledPaper elevation={3}>
      <Stack spacing={3}>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Metrik</InputLabel>
            <Select
              value={selectedMetric}
              onChange={handleMetricChange}
              label="Metrik"
            >
              {metrics.map((metric) => (
                <MenuItem key={metric.value} value={metric.value}>
                  {metric.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Benutzer</InputLabel>
            <Select
              multiple
              value={selectedUsers}
              onChange={handleUserChange}
              label="Benutzer"
            >
              {users.map((user) => (
                <MenuItem key={user} value={user}>
                  {user}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <ChartContainer>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                angle={-45}
                textAnchor="end"
                height={70}
                interval={0}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar
                dataKey={selectedMetric}
                fill={getBarColor(selectedMetric)}
                name={metrics.find((m) => m.value === selectedMetric)?.label}
              />
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      </Stack>
    </StyledPaper>
  );
};
