import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileMenu from "./MobileMenu";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import "./Menu.css";
import { IconWrapper } from "../Assets/commonStyles/styles";
import CopyrightIcon from "@mui/icons-material/Copyright";
// import DesktopMenu from "./DesktopMenu";

export default function Menu(props) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const { t } = useTranslation();
  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };
  // isMobile ? (
  //   <MobileMenu />
  // ) : (
  const isHiddenOverflow =
    props.content.type.name === "NewProgram" ||
    props.content.type.name === "Lk" ||
    props.content.type.name === "Patients" ||
    props.content.type.name === "ET" ||
    window.location.pathname.includes("/programs/create");

  var menu = isMobile ? (
    <Row>
      <Col>
        <div className="program-overview">{props.content}</div>
        <MobileMenu />
      </Col>
    </Row>
  ) : (
    // <DesktopMenu content={props.content} isHiddenOverflow={isHiddenOverflow} />
    <Row>
      <Col md={1} className="app-menu vh-100 d-xxs-none d-md-block">
        <NavLink to="/patients" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/patienten.svg" alt="patients" />
              <span className="menu-title">{t("menu.patients")}</span>
            </div>
          </Row>
        </NavLink>
        <NavLink to="/programs" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/programs_icon.svg" alt="programs" />
              <span className="menu-title">{t("menu.programs")}</span>
            </div>
          </Row>
        </NavLink>
        <NavLink to="/exercises" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/uebungen.svg" alt="exercises" />
              <span className="menu-title">{t("menu.exercises")}</span>
            </div>
          </Row>
        </NavLink>
        {/* <NavLink to="/materials" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <IconWrapper>
                <img src="/images/camera.svg" alt="materials" />
              </IconWrapper>
              <span className="menu-title">{t("menu.materials")}</span>
            </div>
          </Row>
        </NavLink> */}
        <NavLink to="/analysis" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img
                src="/images/chart_grey_cropped.svg"
                alt="analysis"
                style={{ width: "20px", height: "20px" }}
              />
              <span className="menu-title">{t("menu.analysis")}</span>
            </div>
          </Row>
        </NavLink>
        <NavLink to="/preferences" activeclassname="active">
          <Row className="menu-row align-items-center">
            <div className="menu-item no-title">
              <img src="/images/settings_icon.svg" alt="settings" />
              <span className="menu-title">{t("menu.settings")}</span>
            </div>
          </Row>
        </NavLink>
        <Row className="menu-footer d-none d-xxxl-block">
          <Col className="menu-footer-title">
            <CopyrightIcon className="copyright-icon" sx={{ fontSize: 20 }} />
            <span className="footer-text">hoferdigital gmbh</span>
          </Col>
        </Row>
      </Col>
      <Col
        className="program-overview"
        style={{
          overflowY: isHiddenOverflow ? "hidden" : "scroll",
          position: "relative",
        }}
      >
        {props.content}
      </Col>
    </Row>
  );
  return (
    <Container fluid>
      <Header logout={logout} />
      {menu}
    </Container>
  );
}
