import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "../Preferences/Preferences.css";
import "../Programs/Programs.css";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "var(--main-color)",
  "&.Mui-checked": {
    color: "var(--main-color)",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
}));

export default function Permissions() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState();
  var content;

  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/get_permissions_for_group/${id}`;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const url_update =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/settings/set_permission_group`;

  const handleCheckboxChange = (permissionId, authority, value, checked) => {
    let tempAuthority = authority;
    if (checked) {
      tempAuthority = tempAuthority + value;
    } else {
      tempAuthority = tempAuthority - value;
    }
    var params = new FormData();
    params.append("authority", tempAuthority);
    params.append("id", permissionId);
    axios
      .post(url_update, params)
      .then((response) => {
        setPermissions((prevPermissions) => {
          let updatedPermission = response.data;
          return prevPermissions.map((permission) => {
            if (permission.id === permissionId) {
              updatedPermission.name = permission.name;
              return updatedPermission;
            }
            return permission;
          });
        });
      })
      .catch(() => {
        alert("something went wrong");
      });
  };

  useEffect(() => {
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        setPermissions(response.data.permissions);
      })
      .catch(() => {
        alert("something went wrong");
      });
  }, [url]);

  content = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("groups.name")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("groups.read")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("groups.write")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("groups.edit")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("groups.delete")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions &&
            permissions.map((permission) => {
              return (
                <TableRow key={permission.id}>
                  <TableCell>{permission.name}</TableCell>
                  <TableCell>
                    <StyledCheckbox
                      checked={
                        (permission.authority & 1) === 1 ||
                        (permission.authority & 3) === 3 ||
                        (permission.authority & 5) === 5 ||
                        (permission.authority & 9) === 9 ||
                        permission.authority === 15
                      }
                      onChange={(e) =>
                        handleCheckboxChange(
                          permission.id,
                          permission.authority,
                          1,
                          e.target.checked
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <StyledCheckbox
                      checked={
                        (permission.authority & 2) === 2 ||
                        (permission.authority & 6) === 6 ||
                        (permission.authority & 10) === 10 ||
                        permission.authority === 15
                      }
                      onChange={(e) =>
                        handleCheckboxChange(
                          permission.id,
                          permission.authority,
                          2,
                          e.target.checked
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <StyledCheckbox
                      checked={
                        (permission.authority & 4) === 4 ||
                        (permission.authority & 12) === 12 ||
                        permission.authority === 15
                      }
                      onChange={(e) =>
                        handleCheckboxChange(
                          permission.id,
                          permission.authority,
                          4,
                          e.target.checked
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <StyledCheckbox
                      checked={
                        (permission.authority & 8) === 8 ||
                        permission.authority === 15
                      }
                      onChange={(e) =>
                        handleCheckboxChange(
                          permission.id,
                          permission.authority,
                          8,
                          e.target.checked
                        )
                      }
                    />
                  </TableCell>
                  {/* <TableCell>Alle</TableCell>
                  <TableCell>Keine</TableCell> */}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {/* <AddGroup
            show={showAddGroup}
            backdrop="static"
            onHide={() => setShowAddGroup(false)}
            client={clientData.data.client.id}
            setAlertStatusDanger={() => setAlertStatus("danger")}
            setAlertStatusSuccess={() => setAlertStatus("success")}
            alertStatus={alertStatus}
          ></AddGroup> */}
    </>
  );

  return (
    <Container fluid className="">
      <Row>
        <Col md={10}>{content}</Col>
      </Row>
    </Container>
  );
}
