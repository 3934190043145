import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Preview as PreviewIcon,
  Note as NoteIcon,
} from "@mui/icons-material";
import ExerciseThumbnailGrid from "./ExerciseThumbnailGrid";

const PreviewContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(90vh - 180px)",
  width: "100%",
  backgroundColor: "#ffffff",
  position: "relative",
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    height: "calc(90vh - 240px)",
  },
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",
  overflowX: "hidden",
  marginRight: -theme.spacing(1),
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.grey[300],
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

const HeaderActions = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  //   borderBottom: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    backgroundColor: theme.palette.grey[50],
    height: "40px",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 8px) scale(1)",
    color: theme.palette.grey[600],
    "&.Mui-focused": {
      color: theme.palette.grey[600],
    },
  },
  "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-shrink": {
    transform: "translate(14px, -12px) scale(0.75)",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[400],
    borderWidth: "2px",
  },
  "& .MuiSelect-select": {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    backgroundColor: theme.palette.grey[50],
    height: "40px",
    "& input": {
      padding: "8px 14px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400],
      borderWidth: "2px",
    },
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 8px) scale(1)",
    color: theme.palette.grey[600],
    "&.Mui-focused": {
      color: theme.palette.grey[600],
      transform: "translate(14px, -12px) scale(0.75)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -12px) scale(0.75)",
  },
}));

const ProgramDetailsSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

const NotesSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

const StyledTextArea = styled("textarea")(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5),
  borderRadius: "20px",
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.grey[50],
  resize: "vertical",
  minHeight: "80px",
  fontFamily: "inherit",
  "&:focus": {
    outline: "none",
    borderColor: theme.palette.grey[400],
    borderWidth: "2px",
  },
}));

const ExercisePreviewContainer = ({
  children,
  title,
  onBackClick,
  onPreviewClick,
  toggleNoteVisibility,
  showNotes,
  exerciseCount = 0,
  isExtended = false,
  showProgramDetails = false,
  programName,
  programDetailsValidation = {},
  handleOnChangeProgramDetails,
  goalOptions = [],
  frequencyOptions = [],
  durationOptions = [],
  programDetails = {},
  setShowProgramPreviewModal,
  exercises,
  notes = "",
  onNotesChange,
}) => {
  const theme = useTheme();
  const LandscapeTabletOrSmallDesktop = useMediaQuery(
    theme.breakpoints.between("901", "2000")
  );

  const handleThumbnailClick = (exercise, index) => {
    setShowProgramPreviewModal(true);
  };

  return (
    <PreviewContainer elevation={0}>
      <HeaderActions>
        <Typography variant="h6" color="text.secondary" sx={{ flexGrow: 1 }}>
          {title} {exerciseCount > 0 && `(${exerciseCount})`}
        </Typography>
        <PreviewIcon
          sx={{
            cursor: "pointer",
            color: "grey.500",
            "&:hover": {
              color: "grey.700",
            },
          }}
          onClick={onPreviewClick}
        />
        <NoteIcon
          sx={{
            cursor: "pointer",
            color: "grey.500",
            "&:hover": {
              color: "grey.700",
            },
          }}
          onClick={toggleNoteVisibility}
        />
      </HeaderActions>

      {showProgramDetails && (
        <ProgramDetailsSection>
          <Stack spacing={1.5}>
            <StyledTextField
              fullWidth
              label="Programmname"
              name="name"
              value={programName || ""}
              onChange={(e) =>
                handleOnChangeProgramDetails("name", e.target.value)
              }
              error={programDetailsValidation.name}
              required
              size="small"
            />

            <StyledFormControl fullWidth size="small">
              <InputLabel id="goal-label">Ziel</InputLabel>
              <Select
                value={programDetails.goal || ""}
                name="goal"
                label="Ziel"
                notched
                labelId="goal-label"
                id="goal-label"
                onChange={(e) =>
                  handleOnChangeProgramDetails("goal", e.target.value)
                }
                error={programDetailsValidation.goal}
              >
                {goalOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth size="small">
              <InputLabel id="frequency-label">Frequenz</InputLabel>
              <Select
                value={programDetails.frequency || ""}
                name="frequency"
                label="Frequenz"
                notched
                labelId="frequency-label"
                id="frequency-label"
                onChange={(e) =>
                  handleOnChangeProgramDetails("frequency", e.target.value)
                }
                error={programDetailsValidation.frequency}
              >
                {frequencyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth size="small">
              <InputLabel id="duration-label">Dauer</InputLabel>
              <Select
                value={programDetails.duration || ""}
                name="duration"
                label="Dauer"
                notched
                labelId="duration-label"
                id="duration-label"
                onChange={(e) =>
                  handleOnChangeProgramDetails("duration", e.target.value)
                }
                error={programDetailsValidation.duration}
              >
                {durationOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Stack>
        </ProgramDetailsSection>
      )}

      {showNotes && (
        <NotesSection>
          <Stack spacing={1.5}>
            <StyledTextArea
              placeholder="Fügen Sie hier Ihre Notizen hinzu..."
              value={notes}
              onChange={(e) => onNotesChange?.(e.target.value)}
            />
          </Stack>
        </NotesSection>
      )}

      <ScrollableContent>
        {LandscapeTabletOrSmallDesktop ? (
          <ExerciseThumbnailGrid
            exercises={exercises}
            onThumbnailClick={handleThumbnailClick}
            placeholderCount={12}
          />
        ) : (
          children
        )}
      </ScrollableContent>
    </PreviewContainer>
  );
};

export default ExercisePreviewContainer;
