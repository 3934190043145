import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import styled from "styled-components";

export interface StyledButtonProps {
  $backgroundColor?: string;
  $color?: string;
  $padding?: string;
  $fontSize?: string;
  $hoverBackgroundColor?: string;
  $blurEffect?: string;
  $buttonAlignment?: string;
  $alignmentPx?: string;
  $height?: string;
  $width?: string;
  $border?: string;
  $marginTop?: string;
  $fontWeight?: string;
}

export interface PrimaryButtonProps extends Omit<ButtonProps, "color"> {
  loading?: boolean;
  loadingText?: string;
}

type StyledButtonComponentProps = StyledButtonProps & PrimaryButtonProps;

const StyledButton = styled(Button)<StyledButtonProps>`
  && {
    background-color: ${(props) =>
      props.$backgroundColor || "var(--main-color)"};
    backdrop-filter: ${(props) => props.$blurEffect || "none"};
    color: ${(props) => props.$color || "white"};
    padding: ${(props) => props.$padding || "10px"};
    border: ${(props) => props.$border || "1px solid transparent"};
    border-radius: 15px;
    cursor: pointer;
    font-size: ${(props) => props.$fontSize || "1em"};
    margin-top: ${(props) => props.$marginTop || "0px"};
    margin-${(props) => props.$buttonAlignment}: ${(props) =>
  props.$alignmentPx || "0"};
    height: ${(props) => props.$height || "40px"};
    width: ${(props) => props.$width || "auto"};
    font-weight: ${(props) => props.$fontWeight || "500px"};
    text-transform: none;
    transform: scale(1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: "kiro";

    transition: transform 250ms ease-in-out, filter 250ms ease-in-out, background-color 250ms ease-in-out;

        &:hover {
      background-color: ${(props) =>
        props.$hoverBackgroundColor ||
        "color-mix(in srgb, var(--main-color), white 10%)"};
    }

    .MuiButton-startIcon {
      margin-right: 4px;
      margin-left: 0;
    }
    &:active {
      transform: scale(0.95);
    }

    &.Mui-disabled {
      background-color: #e0e0e0;
      color: #9e9e9e;
      border: 1px solid #d0d0d0;
      cursor: not-allowed;
    }
  }
`;

const StyledProgress = styled(CircularProgress)`
  && {
    color: inherit;
  }
`;

export const PrimaryButton: React.FC<StyledButtonComponentProps> = ({
  children,
  loading = false,
  loadingText,
  disabled,
  ...props
}) => {
  return (
    <StyledButton {...props} disabled={loading || disabled}>
      {loading && <StyledProgress size="1rem" />}
      {loading && loadingText ? loadingText : children}
    </StyledButton>
  );
};

export default PrimaryButton;
