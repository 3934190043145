import React from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

interface CardAddButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CardAddButton: React.FC<CardAddButtonProps> = ({ onClick }) => {
  return (
    <IconButton
      sx={{
        backgroundColor: "var(--main-color)",
        color: "white",
        borderRadius: "12px",
        width: 35,
        height: 35,
        float: "right",
        transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          backgroundColor: "var(--main-color)",
          filter: "brightness(1.05)",
        },
        "&:active": {
          transform: "scale(0.95)",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      <AddIcon />
    </IconButton>
  );
};
