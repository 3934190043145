import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteColor {
    placeholder?: string;
  }
  interface SimplePaletteColorOptions {
    placeholder?: string;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      placeholder: "#e0e0e0",
    },
  },
  typography: {
    fontFamily: '"Kiro", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          padding: 0,
          "& .MuiTooltip-arrow": {
            color: "white",
          },
        },
        arrow: {
          color: "white",
        },
        popper: {
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
        },
      },
    },
  },
});
