import React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  TextField,
  Stack,
  Paper,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CopyAll as CopyAllIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import parse from "html-react-parser";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
  backgroundColor: "#f5f9ea",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  position: "relative",
  "&:hover": {
    boxShadow: theme.shadows[3],
  },
}));

const StyledDragHandle = styled(Box)({
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  width: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "grab",
  color: "#757575",
  "&:active": {
    cursor: "grabbing",
  },
});

const StyledCardMedia = styled(CardMedia)({
  width: 100,
  height: 100,
  objectFit: "cover",
  borderRadius: "4px",
  margin: "8px",
});

const StyledCardContent = styled(CardContent)({
  flex: 1,
  padding: "8px !important",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "4px",
    },
    "& input": {
      padding: "8px",
      textAlign: "center",
    },
  },
  width: "60px",
  marginRight: "8px",
});

const StyledEditPanel = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  backgroundColor: "#fafafa",
}));

const ExercisePreviewCard = ({
  exercise,
  index,
  dragItem,
  dragOverItem,
  handleSort,
  removeExerciseFromList,
  updateProperty,
  changeExerciseType,
  assignSameAttributes,
  showWeight = true,
}) => {
  const [isEditMode, setIsEditMode] = React.useState(false);

  const handleDragStart = (e) => {
    dragItem.current = index;
  };

  const handleDragEnter = (e) => {
    dragOverItem.current = index;
  };

  const handleDragEnd = (e) => {
    handleSort();
  };

  return (
    <StyledCard
      draggable
      onDragStart={handleDragStart}
      onDragEnter={handleDragEnter}
      onDragEnd={handleDragEnd}
      onDragOver={(e) => e.preventDefault()}
    >
      <StyledDragHandle>
        <DragIndicatorIcon />
      </StyledDragHandle>

      <Box ml={3} display="flex" width="100%">
        <StyledCardMedia
          component="img"
          image={process.env.REACT_APP_SOPHYAPP_THUMBS_URL + exercise.image}
          alt={exercise.name}
        />

        <StyledCardContent>
          <Typography variant="subtitle1" gutterBottom>
            {parse(exercise.name)}
          </Typography>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {exercise.reps} {exercise.typeOfExercise} / {exercise.break}s /{" "}
              {exercise.set} sets
            </Typography>

            <Box ml="auto">
              <IconButton
                size="small"
                onClick={() => setIsEditMode(!isEditMode)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => removeExerciseFromList(index)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => assignSameAttributes(exercise)}
              >
                <CopyAllIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>

          {isEditMode && (
            <StyledEditPanel elevation={0}>
              <Stack spacing={2}>
                {showWeight && (
                  <Box display="flex" alignItems="center">
                    <StyledTextField
                      size="small"
                      type="text"
                      value={exercise.weight || ""}
                      onChange={(e) =>
                        updateProperty(index, "weight", e.target.value)
                      }
                      inputProps={{ maxLength: 4 }}
                    />
                    <Typography variant="body2">KG</Typography>
                  </Box>
                )}

                <Box display="flex" alignItems="center">
                  <StyledTextField
                    size="small"
                    type="text"
                    value={exercise.reps}
                    onChange={(e) =>
                      updateProperty(index, "reps", e.target.value)
                    }
                    inputProps={{ maxLength: 4 }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ cursor: "pointer" }}
                    onClick={() => changeExerciseType(index)}
                  >
                    {exercise.typeOfExercise}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <StyledTextField
                    size="small"
                    type="text"
                    value={exercise.break}
                    onChange={(e) =>
                      updateProperty(index, "break", e.target.value)
                    }
                    inputProps={{ maxLength: 4 }}
                  />
                  <Typography variant="body2">SEC</Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <StyledTextField
                    size="small"
                    type="text"
                    value={exercise.set}
                    onChange={(e) =>
                      updateProperty(index, "set", e.target.value)
                    }
                    inputProps={{ maxLength: 4 }}
                  />
                  <Typography variant="body2">SETS</Typography>
                </Box>

                {exercise.note !== undefined && (
                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    placeholder="Exercise note"
                    value={exercise.note || ""}
                    onChange={(e) =>
                      updateProperty(index, "note", e.target.value)
                    }
                  />
                )}
              </Stack>
            </StyledEditPanel>
          )}
        </StyledCardContent>
      </Box>
    </StyledCard>
  );
};

export default ExercisePreviewCard;
