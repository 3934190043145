import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Patients.css";
import "../Programs/Programs.css";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NewExerciseModal, ProgramArchiveModal } from "../Utils/Modals.js";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAccessToken } from "../Utils/Utils.js";
import { handleRemoveImage } from "../Services/imageUtils.js";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachFileModal from "../Modals/AttachFileModal.tsx";
import PatientDesktopHeader from "./PatientDesktopHeader.jsx";

export default function PatientHeader(props) {
  const name =
    props.patient && props.patient.name
      ? props.patient.name.split(" ")
      : undefined;
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:900px)");
  const [showNewExerciseModal, setShowNewExerciseModal] = useState(false);
  const [showAttachFileModal, setShowAttachFileModal] = useState(false);
  const [showNewExerciseModalSpinner, setShowNewExerciseModalSpinner] =
    useState(false);
  const [showProgramArchiveModal, setShowProgramArchiveModal] = useState(false);
  const MAX_WIDTH = 300;
  const MAX_FILE_SIZE = 100000;
  const DEFAULT_IMAGE_PATH = "/images/profile_icon.svg";
  const { t } = useTranslation();

  const getProfileImageUrl = (newUrl = null) => {
    if (newUrl) {
      return newUrl;
    } else if (props.patient?.profile_image_url) {
      return process.env.REACT_APP_S3_PREFIX + props.patient.profile_image_url;
    }
    return DEFAULT_IMAGE_PATH;
  };

  const handleRemoveProfileImage = () => {
    handleRemoveImage(
      getAccessToken,
      props.patient.id,
      setProfileImage,
      "/images/profile_icon.svg",
      "patient",
      setShowRemoveButton
    );
  };

  const handleCreateAccount = async () => {
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_SOPHYAPP_API_URL
        }/de/react/${getAccessToken()}/patient/create_account_for_patient`,
        {
          account_patient_id: props.patient.id,
        }
      );
      const updatedPatient = {
        ...props.patient,
        account_patient_id: response?.data?.account_patient_id,
        is_registered: response?.status === 200,
      };
      props.updatePatient(updatedPatient);

      props.setAlert({
        open: true,
        message: t("alerts.create-account-success"),
        severity: "success",
      });
    } catch (error) {
      console.error("Error creating account", error);
      props.setAlert({
        open: true,
        message: t("alerts.create-account-error"),
        severity: "error",
      });
    }
  };

  const handleVerifyAccount = async () => {
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_SOPHYAPP_API_URL
        }/de/react/${getAccessToken()}/patient/verify_patient`,
        {
          email: props.patient.email,
        }
      );

      const updatedPatient = {
        ...props.patient,
        is_verified: response?.status === 200,
      };
      props.updatePatient(updatedPatient);
      props.setAlert({
        open: true,
        message: t("alerts.verify-account-success"),
        severity: "success",
      });
    } catch (error) {
      console.error("Error verifying account", error);
      props.setAlert({
        open: true,
        message: t("alerts.verify-account-error"),
        severity: "error",
      });
    }
  };

  const handleResetPassword = () => {
    console.log("reset password");
  };

  const [profileImage, setProfileImage] = useState(getProfileImageUrl());
  const [showRemoveButton, setShowRemoveButton] = useState(
    profileImage !== DEFAULT_IMAGE_PATH
  );

  // adjust and outsource/map to imageUtils.js
  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Resizing the image
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 1000) {
        alert(
          "Die Datei ist zu groß. Bitte wählen kleinere Datei mit max. 1MB aus."
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = async (e) => {
        const img = document.createElement("img");
        img.onload = async () => {
          // Create canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const scale = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scale;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Convert canvas to blob
          canvas.toBlob(
            async (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setProfileImage(URL.createObjectURL(blob));

                const formData = new FormData();
                formData.append("profileImage", blob);
                formData.append("account_patient_id", props.patient.id);

                const url =
                  process.env.REACT_APP_SOPHYAPP_API_URL +
                  `/de/react/${getAccessToken()}/upload/upload_account_patient_image`;
                const config = {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                };

                try {
                  const response = await axios.post(url, formData, config);
                  const newProfileImageUrl = getProfileImageUrl(
                    response.data.url
                  );
                  setProfileImage(newProfileImageUrl);
                  props.updateProfileImageUrl(newProfileImageUrl);
                } catch (error) {
                  console.error("Error uploading image", error);
                }
              } else {
                console.error("File too large after resizing");
              }
            },
            "image/jpeg",
            0.95
          ); // Adjust quality as needed
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
      event.target.value = "";
    }
  };

  const createNewProgram = () => {
    // window.location.href = "/programs/create";
    navigate("/programs/create", {
      state: {
        account_patient_id: props.patient.id,
        patient_name: props.patient.name,
      },
    });
  };

  useEffect(() => {
    const newImageUrl = getProfileImageUrl();
    setProfileImage(newImageUrl);
  }, [props.patient]);

  useEffect(() => {
    setShowRemoveButton(profileImage !== DEFAULT_IMAGE_PATH);
    if (profileImage == DEFAULT_IMAGE_PATH) {
      props.updateProfileImageUrl(null);
    }
    // const newProfileImageUrl = getProfileImageUrl();
    // props.updateProfileImageUrl(newProfileImageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage]);

  var contentMobile = isMobile ? (
    <Row className="profile-card-mobile">
      <center>
        <Row>
          <Col>
            <img
              src="/images/avatar_placeholder.png"
              alt="profile-icon"
              className="profile-card-image"
            />
          </Col>
        </Row>
        <Row>
          <Col className="profile-card-name">
            {name[0]} <b>{name.slice(1).join(" ")}</b>
          </Col>
        </Row>
        <Row>
          <Col className="profile-card-mail">{props.patient.email}</Col>
        </Row>
        <Row>
          <Col className="profile-card-diagnostic">
            {props.patient.diagnostic}
          </Col>
        </Row>
        <Row className="patient-mobile-actions">
          <Col xs={3}>
            <div
              className="patient-mobile-icons"
              onClick={() => {
                props.editPatient();
              }}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/pen.svg"
                alt="edit patient"
              />
              <div className="patient-mobile-icons-text">
                {t("patients.edit-patient-mobile")}
              </div>
            </div>
          </Col>
          <Col xs={3}>
            <div
              className="patient-mobile-icons"
              onClick={() => setShowNewExerciseModal(true)}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/camera.svg"
                alt="film patient"
              />

              <div className="patient-mobile-icons-text">
                {t("patients.film")}
              </div>
            </div>
          </Col>
          <Col xs={3}>
            <div
              className="patient-mobile-icons"
              onClick={() => setShowProgramArchiveModal(true)}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/archive.svg"
                alt="archived programs"
              />

              <div className="patient-mobile-icons-text">
                {t("patients.archive")}
              </div>
            </div>
          </Col>
          <Col xs={3}>
            <div
              className="patient-mobile-icon-new-program"
              onClick={() => createNewProgram()}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/plus.svg"
                alt="add program"
              />

              <div className="patient-mobile-new-program-text">
                {t("patients.new-program-short")}
              </div>
            </div>
          </Col>
        </Row>
      </center>
      <NewExerciseModal
        show={showNewExerciseModal}
        backdrop="static"
        onHide={() => setShowNewExerciseModal(false)}
        setLoader={() => setShowNewExerciseModalSpinner(true)}
        hideLoader={() => setShowNewExerciseModalSpinner(false)}
      />
      <ProgramArchiveModal
        show={showProgramArchiveModal}
        onHide={() => setShowProgramArchiveModal(false)}
        patientId={props.patient.id}
        patientName={props.patient.name}
      />
      <Spinner
        show={showNewExerciseModalSpinner}
        animation="grow"
        role="status"
        style={{
          width: "20rem",
          height: "20rem",
          color: "var(--main-color)",
          top: "30%",
          right: "45%",
          zIndex: "10000",
          display: showNewExerciseModalSpinner ? "block" : "none",
        }}
        className="spinner"
      >
        <span className="visually-hidden"></span>
      </Spinner>
    </Row>
  ) : null;

  var content = name ? (
    <>
      <PatientDesktopHeader
        patient={props.patient}
        profileImage={profileImage}
        showRemoveButton={showRemoveButton}
        onRemoveImage={handleRemoveProfileImage}
        onImageChange={handleProfileImageChange}
        onEditPatient={props.editPatient}
        onNewExercise={() => setShowNewExerciseModal(true)}
        onShowArchive={() => setShowProgramArchiveModal(true)}
        onAttachFile={() => setShowAttachFileModal(true)}
        onCreateProgram={createNewProgram}
        onCreateAccount={handleCreateAccount}
        onVerifyAccount={handleVerifyAccount}
        onResetPassword={handleResetPassword}
        t={t}
      />

      <NewExerciseModal
        show={showNewExerciseModal}
        backdrop="static"
        onHide={() => setShowNewExerciseModal(false)}
        setLoader={() => setShowNewExerciseModalSpinner(true)}
        hideLoader={() => setShowNewExerciseModalSpinner(false)}
        patientId={props.patient.id}
        patientName={props.patient.name}
      />

      <ProgramArchiveModal
        show={showProgramArchiveModal}
        onHide={() => setShowProgramArchiveModal(false)}
        patientId={props.patient.id}
        patientName={props.patient.name}
      />

      {showAttachFileModal && (
        <AttachFileModal
          show={showAttachFileModal}
          patientId={props.patient.id}
          patientName={props.patient.name}
          onHide={() => setShowAttachFileModal(false)}
        />
      )}

      <Spinner
        show={showNewExerciseModalSpinner}
        animation="grow"
        role="status"
        style={{
          width: "20rem",
          height: "20rem",
          color: "var(--main-color)",
          top: "30%",
          right: "45%",
          zIndex: "10000",
          display: showNewExerciseModalSpinner ? "block" : "none",
        }}
        className="spinner"
      >
        <span className="visually-hidden"></span>
      </Spinner>
    </>
  ) : null;

  return name ? (isMobile ? contentMobile : content) : null;
}
