export const getNameOfGoalWithId = (id: string) => {
  let name = null;
  switch (id) {
    case "1":
      name = "Schmerzlinderung";
      break;
    case "2":
      name = "Kräftigung";
      break;
    case "3":
      name = "Beweglichkeit";
      break;
    case "4":
      name = "Koordination";
      break;
    case "5":
      name = "Prävention";
      break;
    case "6":
      name = "Entspannung";
      break;
    case "7":
      name = "Ausdauer";
      break;
  }
  return name;
};
