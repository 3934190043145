import React, { useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

// Grid container with responsive columns
const ThumbnailContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  gridTemplateColumns: "repeat(1, 1fr)",
  [`@media (min-width: 600px)`]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [`@media (min-width: 1300px)`]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  [`@media (min-width: 1440px)`]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

// Thumbnail wrapper with hover effects
const ThumbnailBox = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingTop: "100%", // 1:1 Aspect ratio
  backgroundColor: "#f5f5f5",
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

// Image styling
const ThumbnailImage = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "inherit",
});

// Placeholder styling
const PlaceholderBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.placeholder,
  borderRadius: "inherit",
}));

// Custom tooltip content wrapper
const TooltipContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: 300,
  textAlign: "center",
  backgroundColor: "white",
  borderRadius: theme.shape.borderRadius,
}));

// Custom tooltip image
const TooltipImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
}));

const ExerciseThumbnailGrid = ({
  exercises = [],
  onThumbnailClick,
  placeholderCount = 12,
}) => {
  // Calculate number of placeholder items needed
  const placeholders = Array(Math.max(0, placeholderCount - exercises.length))
    .fill(null)
    .map((_, index) => index);

  // Custom tooltip component
  const CustomTooltip = ({ exercise }) => (
    <TooltipContent>
      <TooltipImage
        src={`${process.env.REACT_APP_SOPHYAPP_THUMBS_URL}${exercise.image}`}
        alt={exercise.name}
      />
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {exercise.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {exercise.reps} {exercise.typeOfExercise} • {exercise.set} sets •{" "}
        {exercise.break}s
      </Typography>
    </TooltipContent>
  );

  return (
    <ThumbnailContainer>
      {exercises.map((exercise, index) => (
        <Tooltip
          key={exercise.id}
          title={<CustomTooltip exercise={exercise} />}
          placement="top"
          arrow
          followCursor
          enterDelay={200}
          leaveDelay={200}
        >
          <ThumbnailBox onClick={() => onThumbnailClick(exercise, index)}>
            <ThumbnailImage
              src={`${process.env.REACT_APP_SOPHYAPP_THUMBS_URL}${exercise.image}`}
              alt={exercise.name}
              loading="lazy"
            />
          </ThumbnailBox>
        </Tooltip>
      ))}

      {placeholders.map((index) => (
        <ThumbnailBox key={`placeholder-${index}`}>
          <PlaceholderBox />
        </ThumbnailBox>
      ))}
    </ThumbnailContainer>
  );
};

export default ExerciseThumbnailGrid;
