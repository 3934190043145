import { useState, useEffect } from "react";

export const useVideoControls = (isSafari) => {
  const [showControls, setShowControls] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoClick = (e) => {
    const video = e.currentTarget;

    if (!isPlaying) {
      video.play();
      setIsPlaying(true);
      setShowControls(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleTouchStart = () => {
    setShowControls(true);
    setTimeout(() => {
      if (!isPlaying) {
        setShowControls(false);
      }
    }, 3000);
  };

  const handleMouseEnter = (e) => {
    if (!isSafari) {
      setShowControls(true);
    }
  };

  const handleMouseLeave = (e) => {
    if (!isSafari) {
      setShowControls(false);
    }
  };

  return {
    showControls,
    setShowControls,
    isPlaying,
    setIsPlaying,
    handleVideoClick,
    handleTouchStart,
    handleMouseEnter,
    handleMouseLeave,
  };
};
