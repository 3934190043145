import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  fill: #333;
  img {
    height: 100%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const MaterialContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const MaterialCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
  min-height: 30vh;
  background-color: #ffffff;
  border: 1px solid #cacfd2;
  border-radius: 5px;
  margin-bottom: 2vh;
  overflow: hidden;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
    border: 1px solid #d3d3d3;
  }
`;

export const MaterialCardImage = styled.div`
  width: 200px;
  height: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; // Ensure content is not overflowing
`;

export const MaterialCardTitle = styled.h2`
  padding-top: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.36px;
  color: #000000;
  opacity: 1;
  text-align: center;
`;

export const MaterialCardDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
`;

export const MaterialCardButton = styled.button`
  //   background-color: #00ff00;
  color: #333;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: var(--main-color);
    color: white;

    svg,
    img {
      fill: white;
      color: white;
    }
  }
`;

export const StyledButton = styled(Button)`
  background: var(--main-color);
  border: 1px var(--main-color) solid;

  &:hover {
    background: var(--main-color-hover);
  }
`;

export const MaterialCartFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: auto; /* Ensures the footer is pushed to the bottom */
  padding: 10px;
  box-sizing: border-box;
`;
