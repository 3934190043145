import React, { useState, useEffect, useRef, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Programs.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Loader from "../Utils/Loader";
import { getAccessToken } from "../Utils/Utils.js";
import Button from "react-bootstrap/Button";
import axios from "axios";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { CatalogExerciseCard } from "../Programs/ExerciseCard";
import {
  NewExerciseModal,
  ExerciseModal,
  ProgramPreviewModal,
} from "../Utils/Modals.js";
import { AccountContext } from "../Contexts/Context";
import useMediaQuery from "@mui/material/useMediaQuery";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import PreviewRoundedIcon from "@mui/icons-material/PreviewRounded";
import ProgramPreviewName from "./ProgramPreviewName";
import SelectField from "./SelectField.js";
import {
  goalOptions,
  frequencyOptions,
  durationOptions,
} from "../Utils/selectOptions";
import NoteIcon from "@mui/icons-material/Note";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DraggableCircle from "../../Comp/Utils/DraggableCircle";
import ExercisePreviewModal from "../../Comp/Modals/ExercisePreviewModal";
import { MaterialCardButton } from "../../Assets/commonStyles/styles";
import ExercisePreviewCard from "../Exercises/ExercisePreviewCard";
import ExercisePreviewContainer from "../Exercises/ExercisePreviewContainer";
import { getNameOfGoalWithId } from "../../Utils/getGoalName";

function setExerciseLayout(
  data,
  columns,
  setActiveExercise,
  setShowExerciseModal,
  setExercise,
  addExerciseToList
) {
  let content_block = [];
  let content = null;
  let exerciseCards;
  for (let i = 0; i < data?.length; i += columns) {
    exerciseCards = [];
    for (let j = 0; j < columns; j++) {
      if (i + j < data.length) {
        exerciseCards.push(
          <Col xs={6} md={4} lg={4} xxl={3}>
            <CatalogExerciseCard
              exercise={data[i + j]}
              key={data[i].id}
              setActiveExercise={setActiveExercise}
              setShowExerciseModal={setShowExerciseModal}
              setExercise={setExercise}
              button={true}
              addExerciseToList={addExerciseToList}
            />
          </Col>
        );
      }
    }
    content = <Row className="program-exercise-row">{exerciseCards}</Row>;
    content_block.push(content);
  }
  return content_block;
}

function getBootstrapBreakpoint() {
  var w = window.innerWidth;
  return w <= 600 ? 2 : w < 900 ? 3 : w < 1440 ? 3 : w < 1700 ? 4 : 4;
}

function getColumns() {
  var w = window.innerWidth;
  return w < 1440 ? 20 : 30;
}

function getWidth() {
  return window.innerWidth;
}

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

function NewProgram(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let breadcrumb_path_text = "Neues Programm erstellen";
  const { t, i18n } = useTranslation();
  let create_program_url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/program/create_program_template`;
  if (location.state && location.state.account_patient_id) {
    // view for creating a program
    create_program_url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/program/create_program`;
  }
  if (location.state && location.state.program_template_update) {
    // view for creating a program
    create_program_url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/program/create_program_template`;
  }
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/sources/index`;
  const url_program_templates =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/program/index`;
  let content = null;
  let availableTemplates = null;
  const [allTemplates, setAllTemplates] = useState([]);
  const [templateOptionElements, setTemplateOptionElements] = useState([]);
  const [showNewExerciseModal, setShowNewExerciseModal] = useState(false);
  const [showExerciseModal, setShowExerciseModal] = useState(false);
  const [showProgramPreviewModal, setShowProgramPreviewModal] = useState(false);
  const [activeExercise, setActiveExercise] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [selectedProgramTemplate, setSelectedProgramTemplate] = useState();
  const { userData, applicationName } = useContext(AccountContext);
  const isMobile = useMediaQuery("(max-width:900px)");
  const smallMobile = useMediaQuery("(max-width:500px");
  const [total, setTotal] = useState();
  const [showStepOne, setShowStepOne] = useState(true);
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [mobileFilters, setMobileFilters] = useState(false);
  const [favorites, setFavorites] = useState();
  const label = { inputProps: { "aria-label": "Vorschau" } };
  const [preview, setPreview] = useState(true);
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  const [filterSidebarWidth, setFilterSidebarWidth] = useState(2);
  const [mainContentWidth, setMainContentWidth] = useState(8);
  const [previewSidebarWidth, setPreviewSidebarWidth] = useState(2);
  const columnRef = useRef(null);
  const [isPreviewSidebarExpanded, setIsPreviewSidebarExpanded] =
    useState(false);
  const [isFilterSidebarExpanded, setIsFilterSidebarExpanded] = useState(false);
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [previewIsExtended, setPreviewIsExtended] = useState(false);
  const [gridSize, setGridSize] = useState(1);
  const [dragged, setDragged] = useState(false);
  const url_favorites =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/settings/get_favorite_groups";
  const [exercises, setExercises] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const [exercise, setExercise] = useState({
    title: "",
    steps: "",
    thumbnail: "",
    video: "",
    patient_id: "",
    body_region: "",
    difficulty: "",
    starting_position: "",
    goal: "",
    tool: "",
  });
  const [params, setParams] = useState({
    account_id: "",
    patient_id: "",
    body_region: "",
    difficulty: "",
    starting_position: "",
    goal: "",
    tool: "",
    provider: "",
    q: "",
    favorites: "",
    page: 1,
    own_exercises: "false",
  });
  const [programDetails, setProgramDetails] = useState({
    account_id: "",
    account_patient_id: "",
    name: "Training",
    frequency: "3",
    duration: "4",
    notes: "",
    goal: "1",
    create_patient: "",
    create_template: "",
  });
  const [programDetailsValidation, setProgramDetailsValidation] = useState({
    account_id: false,
    account_patient_id: false,
    name: false,
    frequency: false,
    duration: false,
    notes: false,
    goal: false,
    create_patient: false,
    create_template: false,
  });
  const [exerciseItems, setExerciseItems] = useState([]);

  const addExerciseToList = async (id, name, image, steps) => {
    let set = 3;
    let reps = 12;
    let breaktime = 60;
    let weight = null;
    let note = "";
    setExerciseItems(
      exerciseItems.concat({
        id: id,
        name: name,
        image: image,
        steps: steps,
        reps: reps,
        break: breaktime,
        weight: weight,
        typeOfExercise: "WH",
        set: set,
        note: note,
      })
    );
  };

  const toggleNoteVisibility = () => {
    setIsNoteVisible((prevState) => !prevState);
  };

  const toggleGridSize = () => {
    setGridSize((prevSize) => (prevSize === 3 ? 1 : prevSize + 1));
  };

  const toggleExtended = () => {
    setPreviewIsExtended((prevState) => {
      const newPreviewIsExtended = !prevState;

      if (newPreviewIsExtended) {
        setMainContentWidth(mainContentWidth - 2);
        setPreviewSidebarWidth(previewSidebarWidth + 2);
      } else {
        setMainContentWidth(mainContentWidth + 2);
        setPreviewSidebarWidth(previewSidebarWidth - 2);
      }

      return newPreviewIsExtended;
    });
    const newColumns = getWidth() <= 1400 ? 2 : 3;
  };

  const handleSidebarToggle = (type) => {
    if (type === "filter") {
      setIsFilterSidebarExpanded(!isFilterSidebarExpanded);
      setFilterSidebarWidth(isFilterSidebarExpanded ? 2 : 3);
    } else {
      setPreviewSidebarWidth(isPreviewSidebarExpanded ? 2 : 3);
      setFilterSidebarWidth(isPreviewSidebarExpanded ? 2 : 1);
      setIsPreviewSidebarExpanded(!isPreviewSidebarExpanded);
    }
  };

  const changeExerciseType = (index) => {
    const updatedItems = exerciseItems.map((item, i) => {
      if (i === index) {
        if (item.typeOfExercise === "WH") {
          return { ...item, typeOfExercise: "SEK" };
        } else if (item.typeOfExercise === "SEK") {
          return { ...item, typeOfExercise: "MIN" };
        } else {
          return { ...item, typeOfExercise: "WH" };
        }
      }
      return item;
    });
    setExerciseItems(updatedItems);
  };

  const removeExerciseFromList = (index) => {
    const newItems = [...exerciseItems];
    newItems.splice(index, 1);
    setExerciseItems(newItems);
    if (index === -1 || newItems.length === 0) {
      localStorage.removeItem("programExerciseItems");
    } else {
      localStorage.setItem("programExerciseItems", JSON.stringify(newItems));
    }
  };

  const assignSameAttributes = (item) => {
    const updatedExerciseItems = exerciseItems.map((exerciseItem) => {
      return {
        ...exerciseItem,
        reps: item.reps,
        break: item.break,
        set: item.set,
      };
    });
    setExerciseItems(updatedExerciseItems);
  };

  const removeAllItemsFromList = () => {
    setExerciseItems([]);
    localStorage.removeItem("programExerciseItems");
  };

  const updateProperty = (index, property, newValue) => {
    const updatedItems = exerciseItems.map((item, i) => {
      if (i === index) {
        return { ...item, [property]: newValue };
      }
      return item;
    });
    setExerciseItems(updatedItems);
  };

  function htmlToPlainText(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  // function handleColumnResize(column, resizer) {
  //   let x = 0;
  //   let w = 0;

  //   const onMouseMove = (e) => {
  //     const dx = e.clientX - x;
  //     const newWidth = w + dx;
  //     column.style.width = newWidth + "px";
  //   };

  //   const onMouseUp = () => {
  //     document.documentElement.removeEventListener("mousemove", onMouseMove);
  //     document.documentElement.removeEventListener("mouseup", onMouseUp);
  //   };

  //   resizer.addEventListener("mousedown", (e) => {
  //     x = e.clientX;
  //     w = column.offsetWidth;
  //     document.documentElement.addEventListener("mousemove", onMouseMove);
  //     document.documentElement.addEventListener("mouseup", onMouseUp);
  //   });
  // }

  const additionalOptions =
    userData.subscription.license_name === "treatsoft"
      ? [
          { value: "9", label: t("options.duration.9") },
          { value: "10", label: t("options.duration.10") },
          { value: "11", label: t("options.duration.11") },
          { value: "12", label: t("options.duration.12") },
          { value: "13", label: t("options.duration.13") },
          { value: "14", label: t("options.duration.14") },
          { value: "15", label: t("options.duration.15") },
          { value: "16", label: t("options.duration.16") },
        ]
      : [];
  const combinedDurationOptions = durationOptions(t).concat(additionalOptions);

  // toDo combine with additionalOptions
  const additionalOptionsMobile =
    userData.subscription.license_name === "treatsoft" ? (
      <React.Fragment>
        <option value="9">{t("options.duration.9")}</option>
        <option value="10">{t("options.duration.10")}</option>
        <option value="11">{t("options.duration.11")}</option>
        <option value="12">{t("options.duration.12")}</option>
        <option value="13">{t("options.duration.13")}</option>
        <option value="14">{t("options.duration.14")}</option>
        <option value="15">{t("options.duration.15")}</option>
        <option value="16">{t("options.duration.16")}</option>
      </React.Fragment>
    ) : null;
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedValidation = { ...programDetailsValidation };
    Object.keys(programDetails).forEach((key) => {
      if (programDetails[key] === "") {
        updatedValidation[key] = true;
      } else {
        updatedValidation[key] = false;
      }
    });
    setProgramDetailsValidation(updatedValidation);
    if (
      programDetailsValidation.name ||
      programDetailsValidation.goal ||
      programDetailsValidation.frequency ||
      programDetailsValidation.duration
    ) {
      alert(
        "Bitte alle erforderlichen Felder ausfüllen und mindestens eine Übunge dem Programm hinzufügen"
      );
      return;
    } else if (exerciseItems.length == 0) {
      alert("Es wurde noch kein Übung hinzugefügt");
      return;
    }
    var params = new FormData();
    params.append("program[account_id]", programDetails.account_id);
    if (programDetails.notes !== undefined) {
      params.append("program[description]", programDetails.notes);
    }
    if (location.state && location.state.account_patient_id) {
      params.append(
        "program[account_patient_id]",
        location.state.account_patient_id
      );
    }
    params.append("program[goal]", programDetails.goal);
    params.append("program[frequency]", programDetails.frequency);
    params.append("program[duration]", programDetails.duration);
    params.append("program[name]", programDetails.name);
    // alternative JSON.stringify key:value
    exerciseItems.map((item, index) => {
      params.append(
        `program[exercises_attributes][${index}][source_id]`,
        item.id
      );
      params.append(
        `program[exercises_attributes][${index}][weight]`,
        item.weight === "" ? 0 : item.weight
      );
      params.append(
        `program[exercises_attributes][${index}][series]`,
        item.set === "" ? 0 : item.set
      );
      params.append(
        `program[exercises_attributes][${index}][repetitions]`,
        item.reps === "" ? 1 : item.reps
      );
      params.append(
        `program[exercises_attributes][${index}][break]`,
        item.break === "" ? 0 : item.break
      );
      params.append(
        `program[exercises_attributes][${index}][sort_order]`,
        index
      );
      params.append(`program[exercises_attributes][${index}][note]`, item.note);
      params.append(
        `program[exercises_attributes][${index}][type_of_exercise]`,
        item.typeOfExercise
      );
    });
    if (
      location.state &&
      (location.state.program_update || location.state.program_template_update)
    ) {
      params.append("program[id]", location.state.program_data_edit.id);
      params.append("update", true);
      if (programDetails.account_id == null) {
        params.append(
          "program[account_id]",
          location.state.program_data_edit.account_id
        );
      }
    }
    if (
      programDetails.create_patient &&
      programDetails.create_patient == true
    ) {
      var paramsPatient = new FormData();
      paramsPatient.append(
        "account_patient_id",
        location.state.account_patient_id
      );
      paramsPatient.append("style", applicationName);
      axios
        .post(
          process.env.REACT_APP_SOPHYAPP_API_URL +
            `/de/react/${getAccessToken()}/patient/create_account_for_patient` +
            ".json",
          paramsPatient
        )
        .then(function (response) {});
    }
    axios
      .post(create_program_url + ".json", params)
      .then(function (response) {
        let program_id = response.data.program_id;
        if (programDetails.create_template) {
          axios
            .post(
              process.env.REACT_APP_SOPHYAPP_API_URL +
                `/de/react/${getAccessToken()}/program/create_program_template` +
                ".json",
              params
            )
            .then(function (response) {
              if (location.state && location.state.account_patient_id) {
                navigate({
                  pathname: "/programs/" + program_id,
                  showSuccessAlert: true,
                });
              } else {
                navigate({
                  pathname:
                    "/program_template/" + response.data.program_template_id,
                  showSuccessAlert: true,
                });
              }
            })
            .catch(function (error) {});
        }
        if (location.state && location.state.account_patient_id) {
          navigate({
            pathname: "/programs/" + response.data.program_id,
            showSuccessAlert: true,
          });
        } else {
          navigate({
            pathname: "/program_template/" + response.data.program_template_id,
            showSuccessAlert: true,
          });
        }
      })
      .catch(function (error) {
        // setAlertVariant("danger");
        // setAlertHeader("Nicht gespeichert");
        // setAlertBody("Daten konnten nicht gespeichert werden");
        // setShowAlert(true);
        // setTimeout(() => {
        //   setShowAlert(false);
        // }, 3000);
      });
  };

  const handleOnChangeModal = async (target, value) => {
    setExercise({
      ...exercise,
      [target]: value,
    });
  };

  const handleOnChangeFilter = async (target, value) => {
    setParams({
      ...params,
      [target]: value,
      page: 1,
    });
  };

  const handleOnChangeProgramDetails = async (target, value) => {
    setProgramDetails({
      ...programDetails,
      [target]: value,
    });
  };

  const handleOnChangeTemplate = async (target, value, availableTemplates) => {
    allTemplates.map((template, index) => {
      if (template.id == value) {
        setSelectedProgramTemplate(template.id);
        const url =
          process.env.REACT_APP_SOPHYAPP_API_URL +
          `/de/react/${getAccessToken()}/program/get_program_template/${
            template.id
          }`;
        axios
          .get(url, {
            headers: headers,
            params: {
              id: template.id,
            },
          })
          .then((response) => {
            let weight = null;
            const newExerciseItems = response.data[0].exercises.map(
              (exercise) => ({
                id: exercise.source_id,
                name: exercise.name,
                image: exercise.thumb_url.substring(
                  exercise.thumb_url.lastIndexOf("/") + 1
                ),
                reps: exercise.repetitions,
                break: exercise.break,
                weight: weight,
                set: exercise.series,
                note: exercise.note,
              })
            );
            setExerciseItems(newExerciseItems);
            setProgramDetails({
              ...programDetails,
              name: template.name,
              goal: template.goal,
              frequency: template.frequency,
              duration: template.duration,
              notes: template.description,
            });
          })
          .catch(() => {});
      }
    });
  };

  const handlePreview = (event) => {
    setPreview(event.target.checked);
  };

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleOnDragStart = (e, index) => {};
  const handleOnDragEnter = (e, index) => {};
  const handleOnDragEnd = (e, index) => {};
  const handleTouchStart = (e) => {};
  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const touchX = touch.clientX;
    const touchY = touch.clientY;

    const itemElements = document.querySelectorAll(".list-item-wrapper");

    // Iterate over the rectangles and check if the touch is within the bounds of any of them
    for (let i = 0; i < itemElements.length; i++) {
      const element = itemElements[i];
      const rect = element.getBoundingClientRect();
      if (
        touchX >= rect.left &&
        touchX <= rect.right &&
        touchY >= rect.top &&
        touchY <= rect.bottom
      ) {
        // Update the drag over item
        dragOverItem.current = i;
        break;
      }
    }
  };
  const editExercisePreview = (index) => {
    var editOption = "";
    if (previewIsExtended) {
      editOption = "-horizon";
    } else {
      editOption = "";
    }
    const exercisePreviewElement = document.querySelector(
      ".edit-exercise-preview" + editOption + "-" + index
    );
    exercisePreviewElement.style.display =
      exercisePreviewElement.style.display === "none" ? "block" : "none";
    if (exercisePreviewElement.style.display === "block") {
      if (editOption == "-horizon") {
        var exercisePreviewElementTmp = document.querySelector(
          ".edit-exercise-preview-" + index
        );
        exercisePreviewElementTmp.style.display = "none";
      } else {
        var exercisePreviewElementTmp = document.querySelector(
          ".edit-exercise-preview-horizon-" + index
        );
        exercisePreviewElementTmp.style.display = "none";
      }
    }
  };

  const handleSort = () => {
    if (dragOverItem.current == null) {
      return;
    }
    let _items = [...exerciseItems];

    //Remove and save dragged item content
    const draggedItemContent = _items.splice(dragItem.current, 1)[0];

    //switch position
    _items.splice(dragOverItem.current, 0, draggedItemContent);

    //reset exercise items
    dragItem.current = null;
    dragOverItem.current = null;
    setExerciseItems(_items);
  };

  const onPageChange = (event, value) => {
    setParams({
      ...params,
      page: value,
    });
  };

  function adjustTextareaWidth(textarea) {
    const minWidth = 100; // Minimum width in pixels
    const maxWidth = 200; // Maximum width in pixels
    const charWidth = 8; // Estimated width of a single character in pixels

    const text = textarea.value.replace(/\s/g, ""); // Remove whitespace
    const contentWidth = text.length * charWidth; // Calculate content width based on character count
    const newWidth = Math.min(Math.max(contentWidth, minWidth), maxWidth); // Clamp the width between minWidth and maxWidth

    textarea.style.width = newWidth + "px"; // Apply the new width
  }

  const scrollToTop = () => {
    const scrollContainer = isMobile
      ? document.querySelector(".program-overview")
      : document.querySelector(".sb-main-content");
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      const savedExerciseItems = localStorage.getItem("programExerciseItems");
      if (savedExerciseItems) {
        setExerciseItems(JSON.parse(savedExerciseItems));
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [params.page]);

  useEffect(() => {
    const savedExerciseItems = localStorage.getItem("programExerciseItems");
    if (savedExerciseItems) {
      setExerciseItems(JSON.parse(savedExerciseItems));
    }
  }, []);

  // Add cleanup when navigating away or component unmounts
  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem('programExerciseItems');
  //   };
  // }, []);

  useEffect(() => {
    if (exerciseItems?.length > 0) {
      localStorage.setItem(
        "programExerciseItems",
        JSON.stringify(exerciseItems)
      );
    }
  }, [exerciseItems]);

  useEffect(() => {
    axios
      .get(url, {
        headers: headers,
        params: {
          account_id: params.account_id,
          patient_id:
            location.state && location.state.account_patient_id
              ? location.state.account_patient_id
              : params.patient_id,
          body_region: params.body_region,
          difficulty: params.difficulty,
          starting_position: params.starting_position,
          goal: params.goal,
          tool: params.tool,
          provider: params.provider,
          q: params.q,
          favorites: params.favorites,
          page: params.page,
          own_exercises: params.own_exercises,
        },
      })
      .then((response) => {
        setExercises({
          loading: false,
          data: response.data?.sources,
          error: false,
        });
        setTotal(response.data?.total);
      })
      .catch(() => {
        setExercises({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [params]);

  // Action UPDATE
  useEffect(() => {
    if (
      location.state &&
      (location.state.program_update ||
        location.state.program_template_update ||
        location.state.program_recycle)
    ) {
      let weight = null;
      const newExerciseItems = location.state.program_data_edit.exercises.map(
        (exercise) => {
          let type_of_exercise;
          if (
            exercise.type_of_exercise === "repititions" ||
            exercise.type_of_exercise === "WH" ||
            exercise.type_of_exercise === "reps"
          ) {
            type_of_exercise = "WH";
          } else if (exercise.type_of_exercise === "MIN") {
            type_of_exercise = "MIN";
          } else if (
            exercise.type_of_exercise === "Sec" ||
            exercise.type_of_exercise === "sek" ||
            exercise.type_of_exercise === "SEK"
          ) {
            type_of_exercise = "SEK";
          } else {
            type_of_exercise = "WH";
          }

          return {
            id: exercise.source_id,
            name: exercise.name,
            steps: exercise.steps,
            image: exercise.thumb_url.substring(
              exercise.thumb_url.lastIndexOf("/") + 1
            ),
            reps: exercise.repetitions,
            break: exercise.break,
            weight: exercise.weight,
            set: exercise.series,
            note: exercise.note,
            typeOfExercise: type_of_exercise,
          };
        }
      );
      setExerciseItems(newExerciseItems);
      setProgramDetails({
        ...programDetails,
        name: location.state.program_data_edit.name,
        goal: location.state.program_data_edit.goal_id,
        frequency: location.state.program_data_edit.frequency,
        duration: location.state.program_data_edit.duration,
        notes: location.state.program_data_edit.description,
      });
    }
  }, []);

  useEffect(() => {
    axios
      .get(url_program_templates, {
        headers: headers,
        params: {
          account_id: params.account_id,
        },
      })
      .then((response) => {
        availableTemplates = response.data;
        const options = availableTemplates.map((entry) => (
          <option key={entry.id} value={entry.id}>
            {entry.name}
          </option>
        ));
        setTemplateOptionElements(options);
        setAllTemplates(availableTemplates);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    axios
      .get(url_favorites)
      .then((response) => {
        const options = response.data;
        setFavorites(options);
      })
      .catch(() => {});
  }, []);

  if (userData.subscription.programs_count >= userData.program_limit) {
    if (!alertShown) {
      alert("Programmlimit für dein Rechnungsmonat erreicht");
      setAlertShown(true);
    }
    window.location.href = "/";
  }

  if (exercises.error) {
    content = <div>{t("others.dataNotFound")}</div>;
  }

  if (exercises.loading) {
    content = <Loader />;
  }

  if (exercises.data) {
    let exercise_data_rows = setExerciseLayout(
      exercises.data,
      getBootstrapBreakpoint(),
      setActiveExercise,
      setShowExerciseModal,
      setExercise,
      addExerciseToList
    );
    exercise_data_rows.push(
      <div className="pagination-exercises">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(total / 12)}
            color="primary"
            onChange={(event, value) => {
              onPageChange(event, value);
              scrollToTop();
            }}
            page={params.page}
          />
        </Stack>
      </div>
    );
    content = exercise_data_rows;
    if (location.state && location.state.program_update) {
      breadcrumb_path_text = "Programm bearbeiten";
    }
    if (location.state && location.state.program_template_update) {
      breadcrumb_path_text = "Vorlage bearbeiten";
    }
    if (programDetails.create_template) {
      breadcrumb_path_text = "Neue Programmvorlage erstellen";
    }
  }
  var filters = (
    <Col
      xs={12}
      className="sidebar-properties-patient-list sidebar-filter-container"
    >
      {isMobile ? null : "Filter"}
      <center>
        <Row>
          <Col md={12} className="sidebar-filter">
            <select
              name="body_region"
              value={setParams.body_region}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
              className="select-box sidebar-filter-elem"
            >
              <option style={{ display: "none" }} defaultValue>
                {t("exercises.body-region")}
              </option>
              <option value="all">alle</option>
              <option value="CORE">CORE</option>
              <option value="cervical-spine">Hws</option>
              <option value="thoracic-spine">Bws</option>
              <option value="lumbar-spine">Lws</option>
              <option value="UPPER-LIMB">OBERE EXTREMITÄT</option>
              <option value="shoulder">Schulter</option>
              <option value="elbow">Ellbogen</option>
              <option value="hand">Hand</option>
              <option value="LOWER-LIMB">UNTERE EXTREMITÄT</option>
              <option value="hip">Hüfte</option>
              <option value="knee">Knie</option>
              <option value="foot">Fuß</option>
              <option value="legs">Beine</option>
              <option value="arms">Arme</option>
              <option value="chest">Brust</option>
              <option value="body">Ganzkörper</option>
              <option value="abductor">Abduktoren</option>
              <option value="adductor">Adduktoren</option>
              <option value="back">Rücken</option>
              <option value="biceps">Bizeps</option>
              <option value="triceps">Trizeps</option>
              <option value="lungs">Lunge</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="sidebar-filter">
            <select
              name="starting_position"
              value={setParams.starting_position}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
              className="select-box sidebar-filter-elem"
            >
              <option style={{ display: "none" }} defaultValue>
                {t("exercises.position")}
              </option>
              <option value="all">alle</option>
              <option value="lying">liegend</option>
              <option value="sitting">sitzend</option>
              <option value="all-fours">Vierfüßler</option>
              <option value="standing">stehend</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="sidebar-filter">
            <select
              name="goal"
              value={setParams.goal}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
              className="select-box sidebar-filter-elem"
            >
              <option style={{ display: "none" }} defaultValue>
                {t("exercises.goal")}
              </option>
              <option value="all">alle</option>
              <option value="stabilisation/strength">
                Stabilisation/Kraft
              </option>
              <option value="mobilisation">Mobilisation</option>
              <option value="coordination">Koordination</option>
              <option value="stretch">Dehnung</option>
              <option value="endurance">Ausdauer</option>
              <option value="neurodynamic">Neurodynamik</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="sidebar-filter">
            <select
              name="difficulty"
              value={setParams.difficulty}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
              className="select-box sidebar-filter-elem"
            >
              <option style={{ display: "none" }} defaultValue>
                {t("exercises.difficulty")}
              </option>
              <option value="all">alle</option>
              <option value="easy">leicht</option>
              <option value="medium">mittel</option>
              <option value="hard">schwierig</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="sidebar-filter">
            <select
              name="tool"
              value={setParams.tool}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
              className="select-box sidebar-filter-elem"
            >
              <option style={{ display: "none" }} defaultValue>
                {t("exercises.tool")}
              </option>
              <option value="all">alle</option>
              <option value="kettle">Kettleball</option>
              <option value="blackroll">Blackroll</option>
              <option value="theraband">Theraband</option>
              <option value="dumbbell">Kurzhantel</option>
              <option value="barbell">Langhantel</option>
              <option value="pezziball">Pezziball</option>
              <option value="others">Sonstiges</option>
              <option value="none">Kein Hilfsmittel</option>
            </select>
          </Col>
        </Row>
        {applicationName === "medaix" ? null : (
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="provider"
                value={setParams.provider}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("exercises.provider")}
                </option>
                <option value="all">alle</option>
                <option value="sophyapp">sophyapp</option>
                <option value="cornell">Cornell Health University</option>
                <option value="precision">Precision Nutrition</option>
                <option value="physio10">Physio 1.0</option>
              </select>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12} className="sidebar-filter">
            <select
              name="favorites"
              value={setParams.favorites}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
              className="select-box sidebar-filter-elem"
            >
              <option style={{ display: "none" }} defaultValue>
                {t("exercises.favorites")}
              </option>
              <option value="">alle</option>
              {favorites?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.group_name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="sidebar-filter" style={{ marginTop: "5px" }}>
            <input
              type="checkbox"
              name="own_exercises"
              checked={setParams.own_exercises}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.checked)
              }
            />
            <span className="margin-left-1">{t("general.ownExercises")}</span>
          </Col>
        </Row>
      </center>
    </Col>
  );

  var contentStepOne = (
    <Container fluid className="h-100 sidebar-menu">
      <Row>
        <Col className="patient-back-button">
          <a href="/programs" style={{ color: "var(--main-color" }}>
            {" "}
            {t("program.program-back")}{" "}
          </a>
        </Col>
      </Row>
      <Row className="h-100">
        <Col
          xxs={12}
          xs={12}
          lg={2}
          xxl={2}
          xxxl={1}
          className="sidebar-properties-patient-list"
        >
          <div className="grey-background">
            <Row>
              <Col md={12} className="program-sidebar-header">
                Allgemein
              </Col>
            </Row>
            <Row>
              <Col md={12} className="sidebar-filter">
                <select
                  name="template"
                  value={selectedProgramTemplate}
                  onChange={(e) =>
                    handleOnChangeTemplate(
                      e.target.name,
                      e.target.value,
                      availableTemplates
                    )
                  }
                  className="select-box sidebar-filter-elem"
                >
                  <option style={{ display: "none" }} defaultValue>
                    {t("programs.template-selection")}
                  </option>
                  {templateOptionElements}
                </select>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <input
                  type="text"
                  name="name"
                  className={`program-sidebar-input-mobile ${
                    programDetailsValidation.name == true ? "is-invalid" : ""
                  }`}
                  placeholder="Programmname"
                  required
                  maxlength={35}
                  onChange={(e) =>
                    handleOnChangeProgramDetails(e.target.name, e.target.value)
                  }
                ></input>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="sidebar-filter">
                <select
                  name="goal"
                  className={`select-box sidebar-filter-elem ${
                    programDetailsValidation.goal == true ? "is-invalid" : ""
                  }`}
                  value={programDetails.goal}
                  onChange={(e) =>
                    handleOnChangeProgramDetails(e.target.name, e.target.value)
                  }
                >
                  <option style={{ display: "none" }} defaultValue>
                    {t("programs.goal-selection")}
                  </option>
                  <option value="1">Schmerzlinderung</option>
                  <option value="2">Kräftigung</option>
                  <option value="3">Beweglichkeit</option>
                  <option value="4">Koordination</option>
                  <option value="5">Prävention</option>
                  <option value="6">Entspannung</option>
                  <option value="7">Ausdauer</option>
                </select>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xxs={10} xs={10} md={6}>
              <input
                type="search"
                name="q"
                className="searchbar-exercises-mobile"
                placeholder={t("exercises.search")}
                value={setParams.q}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
              ></input>
            </Col>
          </Row>
          <Row>
            <Col
              xxs={12}
              className="filter-title-mobile"
              onClick={() => setMobileFilters(!mobileFilters)}
            >
              {t("exercises.filter")}
            </Col>
          </Row>
          <Row>{mobileFilters ? filters : null}</Row>
          <Row>
            <Col xxs={4} xs={4} md={3}>
              <center>
                <button
                  type="submit"
                  className="btn-new-program-mobile"
                  style={{
                    width: "100%",
                    background: "lightgrey",
                    cursor: "pointer",
                  }}
                  onClick={(e) => navigate("/dashboard")}
                >
                  {t("programs.cancel")}
                </button>
              </center>
            </Col>
            <Col xxs={4} xs={4} md={6}></Col>
            <Col xxs={4} xs={4} md={3}>
              <center>
                <button
                  type="submit"
                  className="btn-new-program-mobile"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setShowStepTwo(true);
                    setShowStepOne(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("programs.continue")}
                </button>
              </center>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={8} xxl={8} xxxl={10} className="sb-main-content h-100">
          <Row>
            <Col md={12} className="overview-exercises">
              {content}
            </Col>
          </Row>
        </Col>
      </Row>
      {isMobile ? (
        <DraggableCircle
          exercises={exerciseItems}
          setExercises={setExerciseItems}
        />
      ) : null}
    </Container>
  );
  var contentStepTwo = (
    <Container fluid className="h-100 sidebar-menu">
      <Row>
        <Col className="patient-back-button">
          <a href="/programs" style={{ color: "var(--main-color" }}>
            {" "}
            {t("program.program-back")}{" "}
          </a>
        </Col>
      </Row>
      <Row>
        <Col xxs={4} xs={4} md={3}>
          <center>
            <button
              type="submit"
              className="btn-new-program-mobile"
              style={{
                width: "100%",
                background: "lightgrey",
                cursor: "pointer",
              }}
              onClick={(e) => {
                setShowStepTwo(false);
                setShowStepOne(true);
              }}
            >
              Zurück
            </button>
          </center>
        </Col>
        <Col xxs={4} xs={4} md={6}></Col>
        <Col xxs={4} xs={4} md={3}>
          <center>
            <button
              type="submit"
              className="btn-new-program-mobile"
              style={{ width: "100%" }}
              onClick={(e) => {
                handleSubmit(e);
              }}
              style={{ cursor: "pointer" }}
            >
              Speichern
            </button>
          </center>
        </Col>
      </Row>
      {/* but this into another component, duplicated code. */}
      <Row>
        <Col
          xxs={12}
          xs={12}
          lg={2}
          xxl={2}
          xxxl={1}
          className="sidebar-properties-mobile"
        >
          <Row className="program-preview-title">
            {t("program.preview")}
            {location.state && location.state.patient_name != undefined
              ? `(${location.state.patient_name})`
              : ""}
          </Row>
          <Row className="program-preview-name">
            {t("profile.name")} {programDetails.name}
          </Row>
          <Row className="program-preview-title">
            {t("programs.goal-select")}:{" "}
            {getNameOfGoalWithId(programDetails.goal)}
          </Row>
          <Row className="list-container">
            {exerciseItems.map((item, index) => (
              <ExercisePreviewCard
                key={index}
                exercise={item}
                index={index}
                dragItem={dragItem}
                dragOverItem={dragOverItem}
                handleSort={handleSort}
                removeExerciseFromList={removeExerciseFromList}
                updateProperty={updateProperty}
                changeExerciseType={changeExerciseType}
                assignSameAttributes={assignSameAttributes}
                showWeight={storedUserData?.setting?.ex_weight}
              />
            ))}
          </Row>

          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="frequency"
                value={programDetails.frequency}
                onChange={(e) =>
                  handleOnChangeProgramDetails(e.target.name, e.target.value)
                }
                className={`select-box sidebar-filter-elem ${
                  programDetailsValidation.frequency == true ? "is-invalid" : ""
                }`}
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("programs.frequency-selection")}
                </option>
                <option value="1">1x wöchentlich</option>
                <option value="2">2x wöchentlich</option>
                <option value="3">3x wöchentlich</option>
                <option value="4">4x wöchentlich</option>
                <option value="5">5x wöchentlich</option>
                <option value="6">6x wöchentlich</option>
                <option value="7">7x wöchentlich</option>
              </select>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col md={12} className="sidebar-filter">
              <select
                name="duration"
                value={programDetails.duration}
                onChange={(e) =>
                  handleOnChangeProgramDetails(e.target.name, e.target.value)
                }
                className={`select-box sidebar-filter-elem ${
                  programDetailsValidation.duration == true ? "is-invalid" : ""
                }`}
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("programs.duration-selection")}
                </option>
                <option value="1">1 Woche</option>
                <option value="2">2 Wochen</option>
                <option value="3">3 Wochen</option>
                <option value="4">4 Wochen</option>
                <option value="5">5 Wochen</option>
                <option value="6">6 Wochen</option>
                <option value="7">7 Wochen</option>
                <option value="8">8 Wochen</option>
                {additionalOptionsMobile}
              </select>
            </Col>
          </Row>
          <Row>
            <Col className="program-notes">
              <textarea
                rows="2"
                cols="30"
                name="notes"
                value={programDetails.notes}
                onChange={(e) =>
                  handleOnChangeProgramDetails(e.target.name, e.target.value)
                }
              ></textarea>
            </Col>
          </Row>
          {location.state && location.state.account_patient_id ? (
            <Row>
              <Col md={12}>
                <input
                  type="checkbox"
                  name="create_patient"
                  checked={programDetails.create_patient}
                  onChange={(e) =>
                    handleOnChangeProgramDetails(
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
                <span className="margin-left-1">
                  {t("patients.wantToCreatePatient")}
                </span>
              </Col>
            </Row>
          ) : null}
          {location.state && location.state.account_patient_id ? (
            <Row className=" margin-bottom-1">
              <Col md={12}>
                <input
                  type="checkbox"
                  name="create_template"
                  checked={programDetails.create_template}
                  onChange={(e) =>
                    handleOnChangeProgramDetails(
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
                <span className="margin-left-1">
                  {t("programs.saveAsTemplate")}
                </span>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
  var contentMain = (
    <>
      <Container fluid className="h-100 sidebar-menu">
        <Row className="new-program-header">
          {/* <div className="fixed-position"> */}
          <Row className="page-header-title">
            <Col md={2} className="pl-0">
              {t("menu.programs")}
            </Col>
            <Col md={4} className="program-preview-title-header">
              {t("program.preview")}
              {location.state && location.state.patient_name != undefined
                ? "(" + location.state.patient_name + ")"
                : ""}
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <Button
                className="bs-button-color"
                type="submit"
                style={{ marginRight: "10px" }}
                onClick={(e) => handleSubmit(e)}
              >
                {t("profile.save")}
              </Button>
              <Button
                type="submit"
                variant="danger"
                onClick={() => removeAllItemsFromList()}
              >
                {getWidth() <= 1400 ? "-" : "Zurücksetzen"}
              </Button>
            </Col>
          </Row>
          <Row className="page-header-breadcrumb">
            <Col>
              <a href="/programs">{t("programs.programsCrumb")}</a>
              <span className="page-header-breadcrumb-active">
                {breadcrumb_path_text}
              </span>
            </Col>
          </Row>
          {/* </div> */}
        </Row>
        <Row className="h-100">
          <Col
            ref={columnRef}
            md={filterSidebarWidth}
            xxl={filterSidebarWidth}
            xxxl={filterSidebarWidth - 1}
            className="sidebar-properties h-100 resizable"
          >
            <div className="fixed-position">
              <Row>
                <Col md={12} className="program-sidebar-header">
                  {t("others.general")}
                </Col>
              </Row>
              <Row>
                <Col md={12} className="sidebar-filter">
                  <select
                    name="template"
                    value={selectedProgramTemplate}
                    onChange={(e) =>
                      handleOnChangeTemplate(
                        e.target.name,
                        e.target.value,
                        availableTemplates
                      )
                    }
                    className="select-box sidebar-filter-elem"
                  >
                    <option style={{ display: "none" }} defaultValue>
                      {t("programs.template-selection")}
                    </option>
                    {templateOptionElements}
                  </select>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col md={12} className="program-sidebar-header margin10">
                  {t("exercises.exerciseFilter")}
                  <Row>
                    <Col md={12}>
                      <input
                        type="search"
                        name="q"
                        className="searchbar-exercises d-none d-sm-block"
                        placeholder={t("exercises.search")}
                        value={setParams.q}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.value)
                        }
                      ></input>
                    </Col>
                    <Col md={12} className="sidebar-filter">
                      <select
                        name="body_region"
                        value={setParams.body_region}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.value)
                        }
                        className="select-box sidebar-filter-elem"
                      >
                        <option style={{ display: "none" }} defaultValue>
                          {t("exercises.body-region")}
                        </option>
                        <option value="all">alle</option>
                        <option value="CORE">CORE</option>
                        <option value="cervical-spine">Hws</option>
                        <option value="thoracic-spine">Bws</option>
                        <option value="lumbar-spine">Lws</option>
                        <option value="UPPER-LIMB">OBERE EXTREMITÄT</option>
                        <option value="shoulder">Schulter</option>
                        <option value="elbow">Ellbogen</option>
                        <option value="hand">Hand</option>
                        <option value="LOWER-LIMB">UNTERE EXTREMITÄT</option>
                        <option value="hip">Hüfte</option>
                        <option value="knee">Knie</option>
                        <option value="foot">Fuß</option>
                        <option value="legs">Beine</option>
                        <option value="arms">Arme</option>
                        <option value="chest">Brust</option>
                        <option value="body">Ganzkörper</option>
                        <option value="abductor">Abduktoren</option>
                        <option value="adductor">Adduktoren</option>
                        <option value="back">Rücken</option>
                        <option value="biceps">Bizeps</option>
                        <option value="triceps">Trizeps</option>
                        <option value="lungs">Lunge</option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="sidebar-filter">
                      <select
                        name="starting_position"
                        value={setParams.starting_position}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.value)
                        }
                        className="select-box sidebar-filter-elem"
                      >
                        <option style={{ display: "none" }} defaultValue>
                          {t("exercises.position")}
                        </option>
                        <option value="all">alle</option>
                        <option value="lying">liegend</option>
                        <option value="sitting">sitzend</option>
                        <option value="all-fours">Vierfüßler</option>
                        <option value="standing">stehend</option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="sidebar-filter">
                      <select
                        name="goal"
                        value={setParams.goal}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.value)
                        }
                        className="select-box sidebar-filter-elem"
                      >
                        <option style={{ display: "none" }} defaultValue>
                          {t("exercises.goal")}
                        </option>
                        <option value="all">alle</option>
                        <option value="stabilisation/strength">
                          Stabilisation/Kraft
                        </option>
                        <option value="mobilisation">Mobilisation</option>
                        <option value="coordination">Koordination</option>
                        <option value="stretch">Dehnung</option>
                        <option value="endurance">Ausdauer</option>
                        <option value="neurodynamic">Neurodynamik</option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="sidebar-filter">
                      <select
                        name="difficulty"
                        value={setParams.difficulty}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.value)
                        }
                        className="select-box sidebar-filter-elem"
                      >
                        <option style={{ display: "none" }} defaultValue>
                          {t("exercises.difficulty")}
                        </option>
                        <option value="all">alle</option>
                        <option value="easy">leicht</option>
                        <option value="medium">mittel</option>
                        <option value="hard">schwierig</option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="sidebar-filter">
                      <select
                        name="tool"
                        value={setParams.tool}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.value)
                        }
                        className="select-box sidebar-filter-elem"
                      >
                        <option style={{ display: "none" }} defaultValue>
                          {t("exercises.tool")}
                        </option>
                        <option value="all">alle</option>
                        <option value="kettle">Kettleball</option>
                        <option value="blackroll">Blackroll</option>
                        <option value="theraband">Theraband</option>
                        <option value="dumbbell">Kurzhantel</option>
                        <option value="barbell">Langhantel</option>
                        <option value="pezziball">Pezziball</option>
                        <option value="others">Sonstiges</option>
                        <option value="none">Kein Hilfsmittel</option>
                      </select>
                    </Col>
                  </Row>
                  {applicationName === "medaix" ? null : (
                    <Row>
                      <Col md={12} className="sidebar-filter">
                        <select
                          name="provider"
                          value={setParams.provider}
                          onChange={(e) =>
                            handleOnChangeFilter(e.target.name, e.target.value)
                          }
                          className="select-box sidebar-filter-elem"
                        >
                          <option style={{ display: "none" }} defaultValue>
                            {t("exercises.provider")}
                          </option>
                          <option value="all">alle</option>
                          <option value="sophyapp">sophyapp</option>
                          <option value="cornell">
                            Cornell Health University
                          </option>
                          <option value="precision">Precision Nutrition</option>
                          <option value="physio10">Physio 1.0</option>
                        </select>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={12} className="sidebar-filter">
                      <select
                        name="favorites"
                        value={setParams.favorites}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.value)
                        }
                        className="select-box sidebar-filter-elem"
                      >
                        <option style={{ display: "none" }} defaultValue>
                          {t("exercises.favorites")}
                        </option>
                        <option value="">alle</option>
                        {favorites?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.group_name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={12}
                      className="sidebar-filter"
                      style={{ marginTop: "10px", color: "black" }}
                    >
                      <input
                        type="checkbox"
                        name="own_exercises"
                        checked={setParams.own_exercises}
                        onChange={(e) =>
                          handleOnChangeFilter(e.target.name, e.target.checked)
                        }
                      />
                      <span className="margin-left-1">
                        {t("general.ownExercises")}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* <div className="resizer"></div> */}
          </Col>
          <Col
            md={mainContentWidth}
            xxl={mainContentWidth}
            xxxl={mainContentWidth + 2}
            className="sb-main-content h-100"
            style={{ overflowY: "scroll", paddingBottom: "100px" }}
          >
            <Row>
              <Col md={12} className="overview-exercises">
                {content}
              </Col>
            </Row>
          </Col>
          <Col
            md={previewSidebarWidth}
            xxl={previewSidebarWidth}
            xxxl={previewSidebarWidth - 1}
            className="sidebar-properties h-100"
            style={{ paddingTop: "0px", paddingLeft: "8px" }}
          >
            <div className="d-flex align-items-start flex-column h-100">
              {/* <ProgramPreviewName
                programDetailsValidation={programDetailsValidation}
                handleOnChangeProgramDetails={handleOnChangeProgramDetails}
                programName={programDetails.name}
              />
              <SelectField
                options={goalOptions(t)}
                programDetailsValidation={programDetailsValidation}
                handleOnChangeProgramDetails={handleOnChangeProgramDetails}
                name="goal"
                target={programDetails.goal}
                labelText={t("programs.goal-selection")}
                labelField={t("programs.goal-select")}
              />
              <SelectField
                options={frequencyOptions(t)}
                programDetailsValidation={programDetailsValidation}
                handleOnChangeProgramDetails={handleOnChangeProgramDetails}
                name="frequency"
                target={programDetails.frequency}
                labelText={t("programs.frequency-selection")}
                labelField={t("programs.frequency-select")}
              />
              <SelectField
                options={combinedDurationOptions}
                programDetailsValidation={programDetailsValidation}
                handleOnChangeProgramDetails={handleOnChangeProgramDetails}
                name="duration"
                target={programDetails.duration}
                labelText={t("programs.duration-selection")}
                labelField="Dauer"
              />
              {isNoteVisible && (
                <Row>
                  <Col className="program-notes">
                    <textarea
                      rows="2"
                      cols={getColumns()}
                      name="notes"
                      value={programDetails.notes}
                      onChange={(e) =>
                        handleOnChangeProgramDetails(
                          e.target.name,
                          e.target.value
                        )
                      }
                      placeholder="Notiz hinzufügen"
                    ></textarea>
                  </Col>
                </Row>
              )} */}
              {preview && (
                <ExercisePreviewContainer
                  title={t("programs.preview-exercises")}
                  exerciseCount={exerciseItems.length}
                  isExtended={previewIsExtended}
                  onBackClick={toggleExtended}
                  onPreviewClick={() => setShowProgramPreviewModal(true)}
                  toggleNoteVisibility={toggleNoteVisibility}
                  showProgramDetails={true}
                  programName={programDetails.name}
                  programDetailsValidation={programDetailsValidation}
                  handleOnChangeProgramDetails={handleOnChangeProgramDetails}
                  goalOptions={goalOptions(t)}
                  frequencyOptions={frequencyOptions(t)}
                  durationOptions={combinedDurationOptions}
                  programDetails={programDetails}
                  exercises={exerciseItems}
                  setShowProgramPreviewModal={setShowProgramPreviewModal}
                  notes={programDetails.notes || ""}
                  onNotesChange={(value) =>
                    handleOnChangeProgramDetails("notes", value)
                  }
                  showNotes={isNoteVisible}
                >
                  {exerciseItems.length > 0 ? (
                    exerciseItems.map((item, index) => (
                      <ExercisePreviewCard
                        key={index}
                        exercise={item}
                        index={index}
                        dragItem={dragItem}
                        dragOverItem={dragOverItem}
                        handleSort={handleSort}
                        removeExerciseFromList={removeExerciseFromList}
                        updateProperty={updateProperty}
                        changeExerciseType={changeExerciseType}
                        assignSameAttributes={assignSameAttributes}
                        showWeight={storedUserData?.setting?.ex_weight}
                      />
                    ))
                  ) : (
                    <div className="text-center p-3">
                      {t("programs.no-exercises")}
                    </div>
                  )}
                </ExercisePreviewContainer>
              )}
              {location.state && location.state.account_patient_id ? (
                <Row>
                  <Col md={12}>
                    <label style={{ cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        name="create_patient"
                        checked={programDetails.create_patient}
                        onChange={(e) =>
                          handleOnChangeProgramDetails(
                            e.target.name,
                            e.target.checked
                          )
                        }
                      />
                      <span className="margin-left-1">
                        {t("patients.wantToCreatePatient")}
                      </span>
                    </label>
                  </Col>
                </Row>
              ) : null}
              {location.state && location.state.account_patient_id ? (
                <Row className=" margin-bottom-1">
                  <Col md={12}>
                    <label style={{ cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        name="create_template"
                        checked={programDetails.create_template}
                        onChange={(e) =>
                          handleOnChangeProgramDetails(
                            e.target.name,
                            e.target.checked
                          )
                        }
                      />
                      <span className="margin-left-1">
                        {t("others.wantToCreateTemplate")}
                      </span>
                    </label>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
      {showNewExerciseModal && (
        <NewExerciseModal
          show={showNewExerciseModal}
          backdrop="static"
          onHide={() => setShowNewExerciseModal(false)}
        />
      )}
      {showExerciseModal && (
        <ExerciseModal
          show={showExerciseModal}
          backdrop="static"
          exercise={activeExercise}
          exerciseData={exercise}
          handleOnChangeModal={(target, value) =>
            handleOnChangeModal(target, value)
          }
          onHide={() => setShowExerciseModal(false)}
        />
      )}
      {showProgramPreviewModal && (
        <ExercisePreviewModal
          show={showProgramPreviewModal}
          handleClose={() => setShowProgramPreviewModal(false)}
          gridSize={gridSize}
          toggleGridSize={toggleGridSize}
          exercises={exerciseItems}
          setExercises={setExerciseItems}
        />
      )}
    </>
  );
  return (
    <div className="h-100">
      {isMobile ? (showStepOne ? contentStepOne : contentStepTwo) : contentMain}
    </div>
  );
}

export default NewProgram;
