import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Programs from "../Programs/Programs";
import PatientHeader from "./PatientHeader";
import PatientCard from "./PatientsCard";
import BreadCrumbs from "../Utils/BreadCrumbs";
import { PatientModal } from "../Utils/Modals";
import { getAccessToken } from "../Utils/Utils.js";
import axios, { CancelToken, isCancel } from "axios";
import "./Patients.css";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loader from "../Utils/Loader";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import FilterListIcon from "@mui/icons-material/FilterList";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import { AccountContext } from "../Contexts/Context";
import Alerts from "../CommonMaterial/Alerts";
import { ALPHABET } from "../../Utils/generateAlphabet";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

// function for treatsoft redirect to account_patient_id
const checkCurrentPatient = async (id, patients, setPatients) => {
  if (id) {
    let index = patients.findIndex((patient) => patient.id == id);
    if (index !== -1) {
      // patient found in patients array
      return [index, null];
    } else {
      // patient not found, make axios request to get patient info
      try {
        const url_patient =
          process.env.REACT_APP_SOPHYAPP_API_URL +
          "/de/react/" +
          getAccessToken() +
          "/patient/index?id=" +
          id;
        const response = await axios.get(url_patient, { headers });
        const patient = response.data;
        // add patient to patients array and set state
        // setPatients([...patients, patient]);
        return [patients.length, patient]; // return index of new entry
      } catch (error) {
        return [0, null];
      }
    }
  } else {
    return [0, null];
  }
};

export default function Patients(props) {
  const { id } = useParams();
  const [modalPatient, setModalPatient] = useState(false);
  const [type, setType] = useState("n");
  // const [currentPatient, setCurrentPatient] = useState(id ? id : 0);
  const [currentPatient, setCurrentPatient] = useState(id ? id : 0);
  const { t } = useTranslation();
  const [modalData, setModalData] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const [mobileShowPatientList, setMobileShowPatientList] = useState(true);
  const { userData } = useContext(AccountContext);
  const [mobileShowPatientDetails, setMobileShowPatientDetails] =
    useState(false);
  const isMobile = useMediaQuery("(max-width:900px)");
  const smallMobile = useMediaQuery("(max-width:500px");
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const cancelTokenRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    letters: [],
    status: [],
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [hasMore, setHasMore] = useState(true);
  const limit = 25;
  const maxPatients = 50;
  let offset = 0;
  var firstLoaded = false;
  const [initial, setInitial] = useState(true);
  const locationUrl =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/settings/get_locations";
  const [patientData, setPatientData] = useState({
    firstname: "",
    lastname: "",
    mail: "",
    diagnostic: "",
    sexuality: "",
    archived: 0,
    location: "",
  });
  const [params, setParams] = useState({
    q: "",
  });

  const updatePatient = (updatedPatient) => {
    const updatedPatients = [...patients.data];
    updatedPatients[currentPatient] = updatedPatient;
    setPatients({ ...patients, data: updatedPatients });
  };

  const handleOnChangeModal = async (target, value) => {
    setPatientData({
      ...patientData,
      [target]: value,
    });
  };

  const handleClickPatient = async (e) => {
    setPatientData({
      firstname: "",
      lastname: "",
      mail: "",
      diagnostic: "",
      sexuality: "",
      archived: 0,
      location: "",
    });
    setType("n");
    setModalPatient(true);
    setModalData("");
  };

  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null);
  };

  const handleSelectChange = (value, type) => {
    setSelectedFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
    setFiltersChanged(true);
    // handleFilterMenuClose();
  };

  const handleClickPatientEdit = async (data) => {
    let name = data.name.split(" ");
    setPatientData({
      firstname: name[0],
      lastname: name.slice(1).join(" "),
      mail: data.email,
      diagnostic: data.diagnostic,
      sexuality: data.sexuality,
      archived: data.archived,
      location: data.location,
    });
    setType("e");
    setModalPatient(true);
    setModalData(data);
  };

  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/patient/index";
  const [patients, setPatients] = useState({
    loading: false,
    data: null,
    error: false,
  });

  function fetchPatients() {
    fetch(url + `?limit=${limit}&offset=${offset}`)
      .then((response) => response.json())
      .then((data) => {
        setPatients((prevPatients) => ({
          ...prevPatients,
          data: [...prevPatients.data, ...data],
        }));
        if (data.length < limit) {
          setHasMore(false);
        }
        offset += limit;
      });
  }

  const updatePatientProfileImageUrl = (newUrl, patientIndex) => {
    const updatedPatients = [...patients.data];
    updatedPatients[patientIndex] = {
      ...updatedPatients[patientIndex],
      profile_image_url: newUrl,
    };
    setPatients({ ...patients, data: updatedPatients });
  };

  const updateProgramImageUrl = (newUrl, patientIndex, programIndex) => {
    const updatedPatients = [...patients.data];

    if (
      !updatedPatients[patientIndex] ||
      !updatedPatients[patientIndex].programs
    ) {
      console.error(
        "Patient or patient program not found at index:",
        patientIndex
      );
      return;
    }

    const updatedPrograms = updatedPatients[patientIndex].programs || [];
    if (!updatedPrograms[programIndex]) {
      console.error("Program not found at index:", programIndex);
      return; // Early return if the specific program is not found
    }

    // Now it's safe to update the program image URL
    updatedPrograms[programIndex].program_image_url = newUrl;

    // Update the patients data with new program details
    const updatedPatient = {
      ...updatedPatients[patientIndex],
      programs: updatedPrograms,
    };

    updatedPatients[patientIndex] = updatedPatient;
    setPatients({ ...patients, data: updatedPatients });
  };

  useEffect(() => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel();
    }

    cancelTokenRef.current = CancelToken.source();

    const lettersParam = selectedFilters.letters.length
      ? `&letters=${encodeURIComponent(
          JSON.stringify(selectedFilters.letters)
        )}`
      : "";

    const statusParam = selectedFilters.status.length
      ? `&status=${encodeURIComponent(JSON.stringify(selectedFilters.status))}`
      : "";

    const filterParams = lettersParam + statusParam;

    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/patient/index/" +
      params.q +
      "?offset=" +
      startIndex +
      "&limit=" +
      maxPatients +
      filterParams;
    if (filtersChanged) {
      setPatients({ data: [], loading: true, error: false });
      setFiltersChanged(false);
    }

    axios
      .get(url, {
        headers: headers,
        cancelToken: cancelTokenRef.current.token,
      })
      .then(async (response) => {
        if (
          initial === true &&
          (patients.data?.length == 0 ||
            patients.data?.length == null ||
            params.q == "")
        ) {
          setInitial(false);
          const [tempIndex, tempPatient] = await checkCurrentPatient(
            id,
            response.data,
            setPatients,
            patients
          );
          setCurrentPatient(tempIndex);
          var tempArr;
          if (tempPatient != null) {
            tempArr = response.data.concat(tempPatient);
          } else {
            tempArr = response.data;
          }
          setPatients({
            data: tempArr,
            loading: false,
            error: false,
          });

          setStartIndex(0);
        } else if (params.q != "" || filtersChanged || startIndex === 0) {
          setPatients({
            data: response.data,
            loading: false,
            error: false,
          });
          setCurrentPatient(0);
          if (startIndex != 0) {
            setStartIndex(0);
          }
        } else {
          setPatients((prevPatients) => ({
            ...prevPatients,
            data: [...prevPatients.data, ...response.data],
          }));
        }
      })
      .catch((response) => {
        if (isCancel(response)) {
          return;
        }
        if (response?.response?.status === 403) {
          setPatients({
            loading: false,
            data: null,
            error: true,
          });
          alert(t("alerts.not-allowed"));
        }
      });

    if (userData.client_id && userData.client_id > 0) {
      axios
        .get(locationUrl)
        .then((response) => {
          setLocations(response.data);
        })
        .catch((error) => {});
    }
  }, [params, startIndex, selectedFilters]);

  const handleOnChangeFilter = debounce((target, value) => {
    setParams({
      ...params,
      [target]: value,
    });
  }, 20);

  const handleShowMore = () => {
    setStartIndex(startIndex + maxPatients);
  };

  let content = null;
  let contentMobileList = null;
  let contentMobileMain = null;

  if (patients.error) {
    content = <div>{t("patients.patients-not-found")}</div>;
  }

  if (patients.loading) {
    if (!firstLoaded) {
      contentDesktop = <Loader />;
    } else {
      content = <Loader />;
    }

    firstLoaded = true;
  }
  if (patients.data) {
    let patient_modal = (
      <PatientModal
        show={modalPatient}
        backdrop="static"
        onHide={() => setModalPatient(false)}
        status={type}
        data={modalData}
        patient={patients.data[currentPatient]}
        name={type == "e" ? "testma" : ""}
        patientData={patientData}
        locations={locations}
        handleOnChangeModal={(target, value) =>
          handleOnChangeModal(target, value)
        }
      ></PatientModal>
    );

    contentMobileList = (
      <Container fluid className="h-100 sidebar-menu">
        <Row className="h-100">
          <Col
            xxs={12}
            lg={3}
            xxl={2}
            xxxl={1}
            className="sidebar-properties-patient-list h-100"
          >
            <Row>
              <Col xxs={12} className="sidebar-header-mobile">
                {t("patients.myPatients")}
              </Col>
            </Row>
            <Row>
              <Col xxs={10} xs={10} md={8}>
                <input
                  type="search"
                  name="q"
                  className="searchbar-exercises-mobile"
                  placeholder={t("patients.search")}
                  value={setParams.q}
                  onChange={(e) =>
                    handleOnChangeFilter(e.target.name, e.target.value)
                  }
                ></input>
              </Col>
              <Col xxs={2} xs={2} md={4} className="">
                <button
                  type="submit"
                  className="btn-new-program-mobile"
                  onClick={() => handleClickPatient()}
                  style={{ cursor: "pointer" }}
                >
                  {smallMobile ? "+" : "Patient hinzufügen"}
                </button>
              </Col>
            </Row>
            <Row>
              <Col xxs={12} lg={12} className="sidebar-patient-list">
                {/* <InfiniteScroll
                  pageStart={0}
                  loadMore={fetchPatients}
                  hasMore={hasMore}
                  loader={
                    <div className="loader" key={0}>
                      Loading ...
                    </div>
                  }
                > */}
                {patients.data?.map((patient, index) => {
                  if (!patient.id) {
                    return null;
                  }
                  return (
                    <PatientCard
                      patient={patient}
                      key={patient.id}
                      index={index}
                      active={currentPatient}
                      setCurrentPatient={() => setCurrentPatient(index)}
                      onClick={() => alert(currentPatient)}
                      setDetails={() => setMobileShowPatientDetails(true)}
                      setList={() => setMobileShowPatientList(false)}
                    ></PatientCard>
                  );
                })}
                {patients.data?.length >= startIndex + maxPatients && (
                  <div onClick={handleShowMore} style={{ cursor: "pointer" }}>
                    {t("patients.show-more")}
                  </div>
                )}
                {/* </InfiniteScroll> */}
              </Col>
            </Row>
          </Col>
        </Row>
        {patient_modal}
      </Container>
    );
    contentMobileMain = (
      <Container fluid className="h-100 sidebar-menu">
        <Row className="h-100">
          <Col
            lg={9}
            xxl={10}
            xxxl={11}
            className="sb-main-content h-100"
            style={{ paddingBottom: "80px" }}
          >
            <Row>
              <Col
                className="patient-back-button"
                onClick={(e) => {
                  setMobileShowPatientList(true);
                  setMobileShowPatientDetails(false);
                }}
              >
                {t("patients.patient")}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="">
                <PatientHeader
                  editPatient={() =>
                    handleClickPatientEdit(patients.data[currentPatient])
                  }
                  showModalPatient={() => setModalPatient(true)}
                  setTypePatient={() => setType("e")}
                  patient={patients.data[currentPatient]}
                  updateProfileImageUrl={(newUrl) =>
                    updatePatientProfileImageUrl(newUrl, currentPatient)
                  }
                ></PatientHeader>
              </Col>
            </Row>
            <Row>
              <Col md={12} fluid>
                <BreadCrumbs value={t("program.overview")}></BreadCrumbs>
              </Col>
            </Row>
            <Row>
              <Col>
                {patients && patients.data.length > 0 ? (
                  <>
                    <Programs
                      program={patients.data[currentPatient]}
                      updateProgramImageUrl={(newUrl, programIndex) =>
                        updateProgramImageUrl(
                          newUrl,
                          programIndex,
                          currentPatient
                        )
                      }
                      currentPatient={currentPatient}
                    ></Programs>
                  </>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        {patient_modal}
      </Container>
    );

    content = (
      <Col lg={9} xxl={10} xxxl={11} className="sb-main-content h-100">
        <Row>
          <Col md={12} className="">
            <PatientHeader
              editPatient={() =>
                handleClickPatientEdit(patients.data[currentPatient])
              }
              showModalPatient={() => setModalPatient(true)}
              setTypePatient={() => setType("e")}
              patient={patients.data[currentPatient]}
              updateProfileImageUrl={(newUrl) =>
                updatePatientProfileImageUrl(newUrl, currentPatient)
              }
              updatePatient={updatePatient}
              setAlert={setAlert}
            ></PatientHeader>
          </Col>
        </Row>

        <Row>
          <Col md={12} fluid>
            <BreadCrumbs value={t("program.overview")}></BreadCrumbs>
          </Col>
        </Row>
        <Row>
          <Col>
            {patients && patients.data.length > 0 ? (
              <Programs
                program={patients.data[currentPatient]}
                updateProgramImageUrl={(newUrl, programIndex) =>
                  updateProgramImageUrl(newUrl, currentPatient, programIndex)
                }
                currentPatient={currentPatient}
              ></Programs>
            ) : null}
          </Col>
        </Row>
      </Col>
    );
    var contentDesktop = (
      <Container fluid className="h-100 sidebar-menu">
        <Row className="h-100">
          <Col
            xxs={12}
            lg={3}
            xxl={2}
            xxxl={1}
            className="sidebar-properties h-100"
          >
            <Row>
              <Col md={12} className="sidebar-header">
                {t("patients.myPatients")}
              </Col>
            </Row>
            <Row>
              <Col xxs={12} lg={12} className="">
                <button
                  type="submit"
                  className="btn-new-program"
                  onClick={() => handleClickPatient()}
                  style={{ cursor: "pointer" }}
                >
                  {window.innerWidth <= 1400
                    ? "Hinzufügen"
                    : "Patient hinzufügen"}
                  <img
                    className="plus-icon"
                    src="/images/plus.svg"
                    alt="add patient"
                  />
                </button>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <input
                  type="search"
                  name="q"
                  className="searchbar-exercises d-none d-sm-block"
                  placeholder={t("patients.search")}
                  value={setParams.q}
                  onChange={(e) =>
                    handleOnChangeFilter(e.target.name, e.target.value)
                  }
                ></input>
              </Col>
              <Col md={3}>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="filter"
                  aria-controls="filter-menu"
                  aria-haspopup="true"
                  onClick={handleFilterMenuClick}
                >
                  <FilterListIcon />
                </IconButton>
                <Menu
                  id="filter-menu"
                  anchorEl={filterMenuAnchorEl}
                  open={Boolean(filterMenuAnchorEl)}
                  onClose={handleFilterMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {t("client.userlastname")}
                    </AccordionSummary>
                    <AccordionDetails sx={{ overflow: "visible" }}>
                      <Autocomplete
                        multiple
                        disablePortal
                        options={ALPHABET}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nachname"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "#000000", // or any color you want for focused state
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#000000", // or any color you want for focused border
                                },
                            }}
                          />
                        )}
                        onChange={(event, value) =>
                          handleSelectChange(value, "letters")
                        }
                        value={selectedFilters.letters || []}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      {t("patients.status")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Autocomplete
                        multiple
                        options={[
                          { label: "Aktiv", value: 0 },
                          { label: "Inaktiv", value: 1 },
                        ]}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "#000000",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#000000",
                                },
                            }}
                          />
                        )}
                        onChange={(event, value) =>
                          handleSelectChange(
                            value.map((item) => item.value),
                            "status"
                          )
                        }
                        value={selectedFilters.status.map((value) => ({
                          label: value === 0 ? "Aktiv" : "Inaktiv",
                          value,
                        }))}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Menu>
              </Col>
            </Row>
            <Row>
              <Col xxs={12} lg={12} className="sidebar-patient-list">
                {patients.data?.map((patient, index) => {
                  // <a href={"/patients/" + patient.id}>
                  if (!patient.id) {
                    return null;
                  }

                  return (
                    <PatientCard
                      patient={patient}
                      key={patient.id}
                      index={index}
                      active={currentPatient}
                      setCurrentPatient={() => setCurrentPatient(index)}
                    ></PatientCard>
                  );
                  // </a>
                })}
                {patients.data?.length >= startIndex + maxPatients && (
                  <div onClick={handleShowMore} style={{ cursor: "pointer" }}>
                    {t("patients.show-more")}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          {content}
        </Row>
        {patient_modal}
      </Container>
    );
  }
  return (
    <div className="h-100">
      {isMobile
        ? mobileShowPatientList
          ? contentMobileList
          : contentMobileMain
        : contentDesktop}
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </div>
  );
}
