import React, { useState } from "react";
import "./Login.css";
import PropTypes from "prop-types";
import { ResetPassword, NewRegistration } from "../Utils/Modals";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../CommonMaterial/PrimaryButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Box from "@mui/material/Box";

const styles = {
  loginContainer: {
    marginTop: "10%",
    paddingTop: "48px",
    marginLeft: "32%",
    marginRight: "32%",
    height: "46.5vh",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1pt solid #E5E7E8",
    borderRadius: "5pt",
    opacity: "1",
  },
  loginImage: {
    position: "absolute",
    left: "60vw",
    right: 0,
  },
  loginFooter: {
    position: "fixed",
    bottom: "1vh",
    textAlign: "center",
    width: "100%",
    color: "#7C878E",
  },
};

async function loginUser(credentials) {
  var params = {
    account: {
      email: credentials.username.toLowerCase(),
      password: credentials.password,
    },
  };
  return fetch(
    process.env.REACT_APP_SOPHYAPP_API_URL + "/de/v2/account/login",
    {
      crossDomain: true,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }
  ).then((data) => data.json());
}

export default function Login({
  setToken,
  setUserData,
  application,
  imagePath,
}) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalRegShow, setModalRegShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationFailed, setRegistrationFailed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [passwordFailed, setPasswordFailed] = useState(false);
  const { t } = useTranslation();
  const [loginFailedHeader, setLoginFailedHeader] = useState(
    t("general.passwordWrong")
  );
  const [loginFailedBody, setLoginFailedBody] = useState(
    t("general.checkData")
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = await loginUser({
        username,
        password,
      });
      setToken(token["access_token"]);
      setUserData(token);
      localStorage.setItem("userData", JSON.stringify(token));
      if (token["access_token"]) {
        // Login successful
      } else {
        if (token["title"] === "Testabo abgelaufen!") {
          setLoginFailedHeader(token["title"]);
          setLoginFailedBody(token["message"]);
        }
        setLoginFailed(true);
      }
    } catch (error) {
      setLoginFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row className="row d-flex justify-content-center">
        <Col>
          <div className="login-page">
            <div className="login-wrapper loginContainer">
              <img
                src={imagePath}
                alt="sophyapp logo"
                className="sophyappimage"
              />
              {/* Add strong semantic hints with 'role' attribute */}
              <form
                onSubmit={handleSubmit}
                id="login-form"
                name="login-form"
                method="post"
                autoComplete="on"
                role="form"
                aria-label="Login Form"
              >
                <div className="login-field-wrapper">
                  <div>
                    <input
                      type="email"
                      name="username"
                      id="email"
                      placeholder={t("user.user")}
                      className="input-field-login"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      autoFocus
                      autoComplete="username"
                      autoCapitalize="off"
                      autoCorrect="off"
                      spellCheck="false"
                      required
                    />
                  </div>
                  <div
                    className="password-field-wrapper"
                    style={{ position: "relative" }}
                  >
                    <Box display="flex" alignItems="center" position="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder={t("user.password")}
                        className="input-field-login"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        autoCapitalize="off"
                        autoCorrect="off"
                        spellCheck="false"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          padding: "4px",
                        }}
                        tabIndex="-1"
                        aria-label={
                          showPassword ? "Hide password" : "Show password"
                        }
                      >
                        {showPassword ? (
                          <VisibilityOff sx={{ color: "#7C878E" }} />
                        ) : (
                          <Visibility sx={{ color: "#7C878E" }} />
                        )}
                      </button>
                    </Box>
                  </div>
                </div>
                <div className="forgot-password-link-wrapper">
                  <button
                    type="button"
                    className="forgot-password-link"
                    onClick={() => setModalShow(true)}
                  >
                    {t("password.forgot")}
                  </button>
                </div>
                <div>
                  <center>
                    <PrimaryButton
                      type="submit"
                      loading={isLoading}
                      loadingText={t("user.sign-in")}
                      $width="140px"
                      $borderRadius="20px"
                    >
                      {t("user.sign-in")}
                    </PrimaryButton>
                  </center>
                </div>
                <div className="new-registration-text">
                  {application === "sophyapp"
                    ? t("user.no-account")
                    : t("user.no-account-third")}
                </div>
                <div>
                  <center>
                    {application === "sophyapp" ? (
                      <a
                        href="#"
                        className="new-account-link"
                        onClick={() => setModalRegShow(true)}
                      >
                        {t("user.register-now")}
                      </a>
                    ) : null}
                  </center>
                </div>
              </form>
              {application === "sophyapp" ? (
                <div className="login-image">
                  <img
                    src="/images/hdi_illu_sophyapp_web-800x800.webp"
                    alt="sophyapp demonstration"
                    style={styles.loginImage}
                  />
                </div>
              ) : null}
            </div>
            <div className="login-footer" style={styles.loginFooter}>
              © hoferdigital gmbh •{" "}
              <a
                href="https://www.hoferdigital.at/impressum-datenschutz/"
                target="_blank"
                rel="noreferrer"
              >
                {t("general.dataPrivacy")}
              </a>{" "}
              •{" "}
              <a
                href="https://www.hoferdigital.at/impressum-datenschutz/"
                target="_blank"
                rel="noreferrer"
              >
                {t("general.terms")}
              </a>{" "}
              •{" "}
              <a
                href="https://hoferdigital.atlassian.net/wiki/spaces/SS/pages/2949775361/therapeut.sophyapp.com+-+Wiki+f+r+Therapeuten"
                target="_blank"
                rel="noreferrer"
              >
                {t("general.support")}
              </a>
            </div>
            <Alert
              variant="danger"
              className="alert-fixed"
              show={loginFailed}
              onClose={() => setLoginFailed(false)}
              dismissible
            >
              <Alert.Heading>{loginFailedHeader}</Alert.Heading>
              <p>{loginFailedBody}</p>
            </Alert>
            <Alert
              variant="success"
              className="alert-fixed"
              show={registrationSuccess}
              onClose={() => setRegistrationSuccess(false)}
              dismissible
            >
              <Alert.Heading>
                {t("general.registrationSuccessful")}
              </Alert.Heading>
              <p>{t("general.checkMailbox")}</p>
            </Alert>
            <Alert
              variant="danger"
              className="alert-fixed"
              show={registrationFailed}
              onClose={() => setRegistrationFailed(false)}
              dismissible
            >
              <Alert.Heading>{t("general.userNotRegistered")}</Alert.Heading>
              <p>{t("general.userExistsAlready")}</p>
            </Alert>
            <Alert
              variant="success"
              className="alert-fixed"
              show={passwordSuccess}
              onClose={() => setPasswordSuccess(false)}
              dismissible
            >
              <Alert.Heading>{t("general.resetPassword")}</Alert.Heading>
              <p>{t("general.checkPassword")}</p>
            </Alert>
            <Alert
              variant="danger"
              className="alert-fixed"
              show={passwordFailed}
              onClose={() => setPasswordFailed(false)}
              dismissible
            >
              <Alert.Heading>{t("general.resetPassword")}</Alert.Heading>
              <p>{t("general.mailCorrect")}</p>
            </Alert>
            {modalShow && (
              <ResetPassword
                show={modalShow}
                setPasswordSuccess={setPasswordSuccess}
                setPasswordFailed={setPasswordFailed}
                application={application}
                onHide={() => setModalShow(false)}
              ></ResetPassword>
            )}
            {modalRegShow && (
              <NewRegistration
                show={modalRegShow}
                setRegistrationSuccess={setRegistrationSuccess}
                setRegistrationFailed={setRegistrationFailed}
                onHide={() => setModalRegShow(false)}
              ></NewRegistration>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  application: PropTypes.string,
  imagePath: PropTypes.string,
};
