import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Row, Col, Container } from "react-bootstrap";
import { getAccessToken } from "../../Components/Utils/Utils";
import { useMediaQuery } from "react-responsive";
import {
  MainContainer,
  MaterialCard,
  MaterialContainer,
  MaterialCardImage,
  MaterialCardTitle,
  MaterialCardDescription,
  MaterialCardButton,
  MaterialCartFooter,
  IconWrapper,
} from "../../Assets/commonStyles/styles";
import PublishIcon from "@mui/icons-material/Publish";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NewMaterialModal from "../../Components/Modals/NewMaterialModal";
import AssignMaterialModal from "../../Components/Modals/AssignMaterialModal";
import {
  getMaterials,
  submitMaterial,
  updateMaterial,
  deleteMaterial,
  publishMaterial,
  mailMaterial,
  addToProgram,
  getAttachmentUrl,
} from "../../Api/api";
import FilePreview from "../../Components/Utils/FilePreview";
import Alerts from "../../Components/CommonMaterial/Alerts";

const MobileContentWrapper = styled.div`
  padding-left: 15px;
`;

const NewMaterialButton = styled.button`
  background-color: var(--main-color, #4caf50);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-weight: bold;

  &:hover {
    background-color: var(--main-color-dark, #45a049);
  }
`;

interface Material {
  title: string;
  description: string;
  file: string;
  category: string;
}

const Materials = () => {
  const [showNewMaterialModal, setShowNewMaterialModal] = useState(false);
  const [showAssignMaterialModal, setShowAssignMaterialModal] = useState(false);
  const [params, setParams] = useState({});
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useState({});
  const [materials, setMaterials] = useState<Material[] | null>(null);
  const [type, setType] = useState<string>("patient");
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedMaterialId, setSelectedMaterialId] = useState<string | null>(
    null
  );
  const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(
    null
  );
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchMaterials();
  }, [activeFilters]);

  useEffect(() => {
    if (!showAssignMaterialModal) {
      setSelectedOptions([]);
    }
  }, [showAssignMaterialModal]);

  const fetchMaterials = async () => {
    try {
      const data = await getMaterials(activeFilters, getAccessToken());
      setMaterials(data);
    } catch (error) {
      console.error("Error fetching materials:", error);
      setAlert({
        open: true,
        message: "Error fetching materials",
        severity: "error",
      });
    }
  };

  const handleOnChangeFilter = (filter: string, value: string) => {
    setActiveFilters({ ...activeFilters, [filter]: value });
  };

  const handleEditMaterial = (material: Material) => {
    setSelectedMaterial(material);
    setShowNewMaterialModal(true);
  };

  const handleOpenAttachment = async (materialId: string) => {
    const url = await getAttachmentUrl(materialId, getAccessToken());
    window.open(url, "_blank");
  };

  const handleNewMaterialSubmit = async (newMaterial: any) => {
    try {
      setLoading(true);
      // await submitMaterial(newMaterial, getAccessToken());
      if (selectedMaterial) {
        await updateMaterial(
          selectedMaterialId as string,
          newMaterial,
          getAccessToken()
        );
      } else {
        await submitMaterial(newMaterial, getAccessToken());
      }
      setShowNewMaterialModal(false);
      setSelectedMaterial(null);
      fetchMaterials(); // Refresh the materials list
      setAlert({
        open: true,
        message: t("materials.submitted"),
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error submitting material:", error);
      setAlert({
        open: true,
        message: t("materials.error-submit"),
        severity: "error",
      });
      setLoading(false);
    }
  };

  const handleDeleteMaterial = async (materialId: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this material?")) {
      try {
        setLoading(true);
        await deleteMaterial(materialId, getAccessToken());
        fetchMaterials(); // Refresh the materials list
        setLoading(false);
        setAlert({
          open: true,
          message: t("materials.deleted"),
          severity: "success",
        });
      } catch (error) {
        console.error("Error deleting material:", error);
        setAlert({
          open: true,
          message: t("materials.error-delete"),
          severity: "error",
        });
        setLoading(false);
      }
    }
  };

  const handlePublishMaterial = async (
    event: React.FormEvent,
    materialId: string
  ) => {
    event.preventDefault();
    try {
      setLoading(true);
      await publishMaterial(materialId, selectedOptions, getAccessToken());
      fetchMaterials();
      setLoading(false);
      setShowAssignMaterialModal(false);
      setAlert({
        open: true,
        message: t("materials.published"),
        severity: "success",
      });
    } catch (error) {
      console.error("Error publishing material:", error);
      setAlert({
        open: true,
        message: t("materials.error-publish"),
        severity: "error",
      });
      setLoading(false);
    }
  };

  const handleMailMaterial = async (
    event: React.FormEvent,
    materialId: string
  ) => {
    event.preventDefault();
    try {
      setLoading(true);
      await mailMaterial(materialId, selectedOptions, getAccessToken());
      fetchMaterials();
      setLoading(false);
      setShowAssignMaterialModal(false);
      setAlert({
        open: true,
        message: t("materials.mail-sent"),
        severity: "success",
      });
    } catch (error) {
      console.error("Error mailing material:", error);
      setAlert({
        open: true,
        message: t("materials.mail-error"),
        severity: "error",
      });
      setLoading(false);
    }
  };

  const handleAddToProgram = async (
    event: React.FormEvent,
    materialId: string
  ) => {
    event.preventDefault();
    try {
      setLoading(true);
      await addToProgram(materialId, selectedOptions, getAccessToken());
      fetchMaterials();
      setLoading(false);
      setShowAssignMaterialModal(false);
      setAlert({
        open: true,
        message: t("materials.added"),
        severity: "success",
      });
    } catch (error) {
      console.error("Error adding material to program:", error);
      setAlert({
        open: true,
        message: t("materials.error-add"),
        severity: "error",
      });
      setLoading(false);
    }
  };

  var filters = (
    <Row>
      <Col
        md={12}
        className="sidebar-properties-patient-list sidebar-filter-container"
      >
        {isMobile ? (
          <div style={{ textAlign: "center" }}>Filter</div>
        ) : (
          <>Filter</>
        )}
        <center>
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="file_type"
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} value="">
                  {t("materials.file-type")}
                </option>
                <option value="all">alle</option>
                <option value="pdf">PDF</option>
                <option value="image">Bild</option>
              </select>
            </Col>
          </Row>
        </center>
      </Col>
    </Row>
  );

  return (
    <div className="h-100">
      <Container fluid className="h-100 sidebar-menu">
        <Row className="h-100">
          {!isMobile && (
            <Sidebar
              setShowNewExerciseModal={setShowNewMaterialModal}
              setParams={setParams}
              handleOnChangeFilter={handleOnChangeFilter}
              filters={filters}
              sideBarHeaderTitle={t("materials.materials") as string}
              modalButtonTitle={t("materials.new-material") as string}
              searchPlaceholder={t("materials.search") as string}
            />
          )}
          <Col
            md={12}
            lg={isMobile ? 12 : 10}
            xxl={isMobile ? 12 : 10}
            xxxl={isMobile ? 12 : 11}
            className="sb-main-content exercise-overview-main-mobile h-100"
          >
            {isMobile && (
              <MobileContentWrapper>
                <Row className="mb-3">
                  <Col>
                    <NewMaterialButton
                      onClick={() => setShowNewMaterialModal(true)}
                    >
                      {t("materials.new-material")}
                    </NewMaterialButton>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>{filters}</Col>
                </Row>
              </MobileContentWrapper>
            )}
            <Row>
              <Col
                md={12}
                className="overview-exercises exercise-overview-main-mobile"
              >
                <MainContainer>
                  <MaterialContainer>
                    {materials?.map((material: any, index: number) => (
                      <MaterialCard key={index}>
                        <MaterialCardTitle>{material.title}</MaterialCardTitle>
                        <MaterialCardImage
                          onClick={() => handleOpenAttachment(material.id)}
                        >
                          <FilePreview
                            fileUrl={material?.presigned_url}
                            file={material?.location}
                          />
                        </MaterialCardImage>
                        <MaterialCardDescription>
                          {material.description}
                        </MaterialCardDescription>
                        <MaterialCartFooter>
                          <MaterialCardButton
                            onClick={() => {
                              setShowAssignMaterialModal(true);
                              setType("patient");
                              setSelectedMaterialId(material.id);
                            }}
                          >
                            <IconWrapper>
                              <PublishIcon titleAccess="Publish" />
                            </IconWrapper>
                          </MaterialCardButton>
                          <MaterialCardButton
                            onClick={() => {
                              setShowAssignMaterialModal(true);
                              setType("mail");
                              setSelectedMaterialId(material.id);
                            }}
                          >
                            <IconWrapper>
                              <SendIcon titleAccess="Send" />
                            </IconWrapper>
                          </MaterialCardButton>
                          <MaterialCardButton
                            onClick={() => {
                              setShowAssignMaterialModal(true);
                              setType("program");
                              setSelectedMaterialId(material.id);
                            }}
                          >
                            <IconWrapper>
                              <AddIcon titleAccess="Add" />
                            </IconWrapper>
                          </MaterialCardButton>
                          <MaterialCardButton
                            onClick={() => {
                              setSelectedMaterialId(material.id);
                              handleEditMaterial(material);
                            }}
                          >
                            <IconWrapper>
                              <EditIcon titleAccess="Edit" />
                            </IconWrapper>
                          </MaterialCardButton>
                          <MaterialCardButton
                            onClick={() => handleDeleteMaterial(material.id)}
                          >
                            <IconWrapper>
                              <DeleteIcon titleAccess="Delete" />
                            </IconWrapper>
                          </MaterialCardButton>
                        </MaterialCartFooter>
                      </MaterialCard>
                    ))}
                  </MaterialContainer>
                </MainContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {showNewMaterialModal && (
        <NewMaterialModal
          show={showNewMaterialModal}
          onHide={() => setShowNewMaterialModal(false)}
          onSubmit={handleNewMaterialSubmit}
          material={selectedMaterial}
        />
      )}
      {showAssignMaterialModal && (
        <AssignMaterialModal
          show={showAssignMaterialModal}
          onHide={() => setShowAssignMaterialModal(false)}
          type={type}
          handleSubmit={
            type === "patient"
              ? (event: React.FormEvent) =>
                  handlePublishMaterial(event, selectedMaterialId as string)
              : type === "mail"
              ? (event: React.FormEvent) =>
                  handleMailMaterial(event, selectedMaterialId as string)
              : (event: React.FormEvent) =>
                  handleAddToProgram(event, selectedMaterialId as string)
          }
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          loading={loading}
        />
      )}
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity as "success" | "error"}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </div>
  );
};

export default Materials;
