import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Box,
  Button,
  Tooltip,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VideocamIcon from "@mui/icons-material/Videocam";
import ArchiveIcon from "@mui/icons-material/Archive";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddIcon from "@mui/icons-material/Add";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockResetIcon from "@mui/icons-material/LockReset";
import PrimaryButton from "../CommonMaterial/PrimaryButton";
import { useTheme } from "@mui/material/styles";

const HeaderContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
}));

const ProfileImage = styled("img")(({ theme }) => ({
  height: 72,
  width: 72,
  cursor: "pointer",
  borderRadius: "50%",
}));

const RemoveButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  background: "grey",
  color: "white",
  padding: 0,
  minWidth: 15,
  width: 15,
  height: 15,
  "&:hover": {
    background: theme.palette.grey[700],
  },
}));

const AccountStatusChip = styled(Chip)(({ theme }) => ({
  height: 24,
  marginLeft: theme.spacing(1),
  "& .MuiChip-icon": {
    fontSize: 16,
  },
  "& .MuiChip-label": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    lineHeight: "24px",
  },
}));

const AccountActionButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  borderRadius: 12,
  marginLeft: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[300]}`,
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
  },
}));

const PatientDesktopHeader = ({
  patient,
  profileImage,
  showRemoveButton,
  onRemoveImage,
  onImageChange,
  onEditPatient,
  onNewExercise,
  onShowArchive,
  onAttachFile,
  onCreateProgram,
  onCreateAccount,
  onVerifyAccount,
  onResetPassword,
  t, // translation function
}) => {
  const name = patient?.name?.split(" ");
  const theme = useTheme();

  const renderAccountStatus = () => {
    if (!patient.is_registered) {
      return (
        // <Tooltip title={t("patients.no-account-created")}>
        <AccountStatusChip
          icon={<AccountCircleIcon />}
          label={t("patients.no-account")}
          color="default"
          size="small"
        />
        // </Tooltip>
      );
    }

    return (
      // <Tooltip
      //   title={
      //     patient.is_verified
      //       ? t("patients.verified")
      //       : t("patients.not-verified")
      //   }
      // >
      <AccountStatusChip
        icon={<VerifiedUserIcon />}
        label={
          patient.is_verified
            ? t("patients.verified")
            : t("patients.not-verified")
        }
        color={patient.is_verified ? "success" : "warning"}
        size="small"
      />
      // </Tooltip>
    );
  };

  return (
    <HeaderContainer maxWidth={false} sx={{ maxWidth: "1800px" }}>
      <Grid container spacing={1} alignItems="center">
        <Grid
          item
          sx={{
            width: "80px",
            marginRight: 2,
          }}
        >
          <Box position="relative" display="inline-block">
            <label htmlFor={`file-input-${patient.id}`}>
              <ProfileImage src={profileImage} alt="profile-icon" />
            </label>
            {showRemoveButton && (
              <RemoveButton size="small" onClick={onRemoveImage}>
                ×
              </RemoveButton>
            )}
            <input
              id={`file-input-${patient.id}`}
              type="file"
              accept="image/*"
              onChange={onImageChange}
              style={{ display: "none" }}
            />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            minWidth: "200px",
            flex: "0 1 auto",
          }}
        >
          <Box display="flex" alignItems="center" mb={0.5}>
            <Typography variant="h6">
              {name[0]} <strong>{name.slice(1).join(" ")}</strong>
            </Typography>
            {renderAccountStatus()}
          </Box>
          <Box display="flex" alignItems="center" gap="8px">
            <Typography variant="body2" color="text.secondary">
              {patient.email}
            </Typography>
            {!patient.is_registered && (
              <Tooltip title={t("patients.create-account")}>
                <AccountActionButton onClick={onCreateAccount} size="small">
                  <PersonAddIcon fontSize="small" />
                </AccountActionButton>
              </Tooltip>
            )}

            {patient.is_registered && !patient.is_verified && (
              <Tooltip title={t("patients.verify-account")}>
                <AccountActionButton onClick={onVerifyAccount} size="small">
                  <VerifiedUserIcon fontSize="small" />
                </AccountActionButton>
              </Tooltip>
            )}

            {/* {patient.is_registered && (
              <Tooltip title={t("patients.reset-password")}>
                <AccountActionButton onClick={onResetPassword} size="small">
                  <LockResetIcon fontSize="small" />
                </AccountActionButton>
              </Tooltip>
            )} */}
          </Box>

          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {patient.diagnostic}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={8} container spacing={2}>
          <Grid item>
            <PrimaryButton
              startIcon={<EditIcon />}
              onClick={onEditPatient}
              $backgroundColor="white"
              $border={`1px solid ${theme.palette.grey[400]}`}
              $color={theme.palette.grey[600]}
              $hoverBackgroundColor={theme.palette.grey[100]}
              $height="32px"
            >
              {t("patients.edit-patient")}
            </PrimaryButton>
          </Grid>

          <Grid item>
            <PrimaryButton
              startIcon={<VideocamIcon />}
              onClick={onNewExercise}
              $backgroundColor="white"
              $border={`1px solid ${theme.palette.grey[400]}`}
              $color={theme.palette.grey[600]}
              $hoverBackgroundColor={theme.palette.grey[100]}
              $height="32px"
            >
              {t("patients.film-patient")}
            </PrimaryButton>
          </Grid>

          <Grid item>
            <PrimaryButton
              startIcon={<ArchiveIcon />}
              onClick={onShowArchive}
              $backgroundColor="white"
              $border={`1px solid ${theme.palette.grey[400]}`}
              $color={theme.palette.grey[600]}
              $hoverBackgroundColor={theme.palette.grey[100]}
              $height="32px"
            >
              {t("patients.program-archive")}
            </PrimaryButton>
          </Grid>

          <Grid item>
            <PrimaryButton
              startIcon={<AttachFileIcon />}
              onClick={onAttachFile}
              $backgroundColor="white"
              $border={`1px solid ${theme.palette.grey[400]}`}
              $color={theme.palette.grey[600]}
              $hoverBackgroundColor={theme.palette.grey[100]}
              $height="32px"
            >
              {t("patients.attachments")}
            </PrimaryButton>
          </Grid>

          <Grid item>
            <PrimaryButton
              startIcon={<AddIcon />}
              onClick={onCreateProgram}
              $backgroundColor="var(--main-color)"
              $color="white"
              $hoverBackgroundColor="color-mix(in srgb, var(--main-color), white 10%)"
              $height="32px"
            >
              {t("patients.new-program")}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default PatientDesktopHeader;
