import React, { useState, useEffect, useContext, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import "./Utils.css";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "../Utils/Utils.js";
import parse from "html-react-parser";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AWS from "aws-sdk";
import "./fine-uploader-styles.css";
import { useDropzone } from "react-dropzone";
import CryptoJS from "crypto-js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AccountContext } from "../Contexts/Context";
import DeleteIcon from "@mui/icons-material/Delete";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgramCard from "../Programs/ProgramCard";
import {
  handleSort,
  getWidth,
  handleTouchStart,
  handleTouchMove,
  editExercisePreview,
  removeExerciseFromList,
  updateProperty,
  changeExerciseType,
} from "../Exercises/exerciseHelpers.js";
import {
  difficultyOptions,
  toolOptions,
  positionOptions,
  goalFilterOptions,
  bodyRegionOptions,
} from "./selectOptions";

function sendResetPasswordRequest(mail, props) {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/0000/account/reset_password`;
  var params = new FormData();
  params.append("email", mail);
  params.append("style", props.application);
  axios
    .post(url, params)
    .then(function (response) {
      props.setPasswordSuccess(true);
    })
    .catch(function (error) {
      props.setPasswordFailed(true);
    });
}

/* own specific function created for one client due to merging accounts
   function seperated due to clarity and separation + backend client check */
function sendSpecificResetPasswordRequest(mail, pw, pwc) {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/account/reset_specific_password`;
  var params = new FormData();
  params.append("email", mail);
  params.append("pw", pw);
  params.append("pwc", pwc);
  axios
    .post(url, params)
    .then(function (response) {
      alert("Passwort geändert");
    })
    .catch(function (error) {
      alert(
        "Passwort konnte nicht geändert werden. Bitte kontaktieren Sie den Support bzgl. Berechtigungen!"
      );
    });
}

function sendRegistration(mail, password, tnc, props) {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL + "/de/react/0000/account.json";
  var params = new FormData();
  params.append("account[email]", mail);
  params.append("account[password]", password);
  params.append("account[tnc]", tnc === true ? 1 : 0);
  axios
    .post(url, params)
    .then(function (response) {
      props.setRegistrationSuccess(true);
    })
    .catch(function (error) {
      props.setRegistrationFailed(true);
    });
}

const handleRemoveAction = (event, patientId, accountId, onHide) => {
  const confirmed = window.confirm(
    "Bist du sicher, dass du den Patienten löschen möchtest?"
  );
  if (confirmed) {
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/patient/delete_patient`;
    var params = new FormData();
    params.append("account_patient_id", patientId);
    params.append("account_id", accountId);
    axios
      .post(url, params)
      .then(function (response) {
        alert("Erfolgreich gelöscht!");
        window.location.reload();
      })
      .catch(function (error) {
        alert("Es ist ein Fehler aufgetreten: " + error.message);
      });
    onHide();
  }
  event.preventDefault();
};

const handleRemoveExerciseAction = (event, exercise, exerciseData, onHide) => {
  const confirmed = window.confirm(
    "Bist du sicher, dass du diese Übung löschen möchtest? (Seite wird aktualisiert)"
  );
  if (confirmed) {
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/sources/delete_source`;
    var params = new FormData();
    params.append("source_id", exercise.id);
    params.append("account_id", exercise.account_id);
    axios
      .post(url, params)
      .then(function (response) {
        alert("Erfolgreich gelöscht!");
        window.location.reload();
      })
      .catch(function (error) {
        alert("Es ist ein Fehler aufgetreten: " + error.message);
      });
    onHide();
  }
  event.preventDefault();
};

export function ResetPassword(props) {
  const [mail, setMail] = useState("");
  const { t } = useTranslation();
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    sendResetPasswordRequest(mail, props);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("password.forgot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("profile.email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="user@sophyapp.com"
              value={mail}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              autoFocus
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("mails.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

/* workaround for client 46 due to removing mails and want to manage accounts with main account */
export function PasswordResetPopup(props) {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (password !== passwordConfirm) {
      alert("Passwords do not match");
      return;
    } else {
      sendSpecificResetPasswordRequest(
        props.user.email,
        password,
        passwordConfirm
      );
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>
              Passwort zurücksetzen von:{" "}
              <strong>{props.user && props.user.email}</strong>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Neues Passwort</Form.Label>
            <Form.Control
              type="password"
              placeholder="New Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
            <Form.Label>Passwort bestätigen</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              required
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            Zurücksetzen
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

/* in this case we included a specific option for client 46 to set their password directly */
export function AddUserToClient(props) {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    // Additional password validation for client 46
    if (props.client && props.client === 46 && password !== confirmPassword) {
      props.setAlertStatusDanger("Passwords do not match");
      return;
    }

    const url = `${
      process.env.REACT_APP_SOPHYAPP_API_URL
    }/de/react/${getAccessToken()}/settings/invite_user_to_client`;
    let params = new FormData();
    params.append("email", mail);

    // Add passwords to the form data for client 46
    if (props.client && props.client === 46) {
      params.append("password", password);
      params.append("confirm_password", confirmPassword);
    }

    axios
      .post(url, params)
      .then(function (response) {
        props.setAlertStatusSuccess("success");
      })
      .catch(function (error) {
        console.error(error);
        const message = error.response?.data?.message || "An error occurred!";
        props.setAlertStatusDanger(message);
      });

    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("client.adduserheader")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t("profile.email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="user@sophyapp.com"
              value={mail}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              autoFocus
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
          {props.client && props.client === 46 && (
            <>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                <Form.Label>Passwort bestätigen</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("mails.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function AddGroupToClient(props) {
  const [name, setName] = useState("");
  const { t } = useTranslation();
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/settings/create_permission_group`;
    var params = new FormData();
    params.append("name", name);
    // params.append("client_id", props.client_id);
    axios
      .post(url, params)
      .then(function (response) {
        props.setGroup([...props.groups, response.data]);
      })
      .catch(function (error) {
        props.setAlertStatusDanger("danger");
      });
    props.onHide();
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("client.addgroupheader")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("client.groupsname")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Standardgruppe"
              value={name}
              required
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("mails.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function AddLocation(props) {
  const [name, setName] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/settings/add_location`;
    var params = new FormData();
    params.append("name", name);
    axios
      .post(url, params)
      .then(function (response) {
        props.setLocations([...props.locations, response.data]);
      })
      .catch(function (error) {
        alert("Etwas ist schiefgelaufen!");
      });
    props.onHide();
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("settings.locationsHeader")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("settings.locationName")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Kufstein"
              value={name}
              required
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("profile.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function AddUserToGroup(props) {
  const [selectedOption, setSelectedOption] = useState();
  const { t } = useTranslation();
  let options;

  if (props.options && props.options.groups) {
    options = props.options.groups.map((group) => ({
      value: group.id,
      label: group.name,
    }));
  }

  useEffect(() => {
    if (props.selected.group && !selectedOption) {
      const group = options.find(
        (option) => option.value === props.selected.group
      );

      setSelectedOption(group);
    }
  }, [props.selected.group, selectedOption, options]);

  const handleSubmit = (event) => {
    event.preventDefault(); // prevent default form submit behavior
    event.stopPropagation(); // stop event from bubbling up

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }

    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/settings/set_user_group`;
    var params = new FormData();
    params.append("group_id", selectedOption.value);
    params.append("user_id", props.selected?.user?.account_id);
    axios
      .post(url, params)
      .then(function (response) {
        props.setAlertStatusSuccess("success");
      })
      .catch(function (error) {
        props.setAlertStatusDanger("danger");
      });

    props.onHide(); // hide modal after successful form submission
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("client.addusertogroupheader")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("client.groupsname")}</Form.Label>
            <Select
              name="group"
              defaultValue={selectedOption}
              value={selectedOption}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              required
              onChange={(selectedOption) => setSelectedOption(selectedOption)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("mails.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function NewRegistration(props) {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [pwRepeat, setPwRepeat] = useState("");
  const [tnc, setTnc] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (password === pwRepeat) {
      sendRegistration(mail, password, tnc, props);
      props.onHide();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("user.therapist-registration-title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("profile.email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="user@patient.com"
              autoFocus
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("general.passwordLength")}</Form.Label>
            <Form.Control
              type="password"
              pattern=".{8,20}"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("general.repeatPassword")}</Form.Label>
            <Form.Control
              type="password"
              pattern=".{8,20}"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              onChange={(e) => setPwRepeat(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="tnc">
            <Form.Check
              type="checkbox"
              label={
                <span>
                  Ich akzeptiere die Vereinbarung{" "}
                  <a
                    href="https://www.hoferdigital.at/impressum-datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Geschäftsbedingungen
                  </a>{" "}
                  und{" "}
                  <a
                    href="https://app.sophyapp.com/users/generate_pdf/Ihr%20Unternehmen.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Datenschutzbestimmungen{" "}
                  </a>
                </span>
              }
              checked={tnc}
              onChange={(e) => setTnc(e.target.checked)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit" disabled={!tnc}>
            {t("mails.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function createPatient(data, status, account_patient_id, clientSet) {
  let action = status === "n" ? "create" : "update";
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/patient/" +
    action;
  var params = new FormData();
  params.append("account_patient[name]", data.firstname + " " + data.lastname);
  params.append("account_patient[firstname]", data.firstname);
  params.append("account_patient[lastname]", data.lastname);
  params.append("account_patient[email]", data.mail);
  params.append("account_patient[diagnostic]", data.diagnostic);
  params.append("account_patient[sexuality]", data.sexuality);
  params.append("account_patient[archived]", data.archived === true ? 1 : 0);
  if (clientSet) {
    params.append("account_patient[location]", data.location);
  }
  if (action === "update")
    params.append("account_patient[account_patient_id]", account_patient_id);
  axios
    .post(url, params)
    .then(function (response) {
      window.location.href = `/patients/${response.data.id}`;
    })
    .catch(function (error) {
      if (error.response.status === 403) {
        alert("Keine Berechtigung!");
      } else {
        alert("Fehler beim Speichern!");
      }
    });
}

export function NewExerciseModal(props) {
  const smallMobile = useMediaQuery("(max-width:500px");
  const { userData } = useContext(AccountContext);
  const uploadUrl =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/upload/upload_source";
  const { t } = useTranslation();
  const [forAllPatients, setForAllPatients] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileAttributes, setSelectedFileAttributes] = useState([]);
  const [showForm, setShowForm] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/*",
    onDrop: (newAcceptedFiles, fileRejections) => {
      const validFiles = newAcceptedFiles.filter((file) => {
        return file.type.startsWith("image/") || file.type.startsWith("video/");
      });

      if (newAcceptedFiles.length !== validFiles.length) {
        alert(t("general.maxImages"));
      }

      if (acceptedFiles.length + newAcceptedFiles.length <= 6) {
        setAcceptedFiles([...acceptedFiles, ...validFiles]);
        setSelectedFile(newAcceptedFiles);
        const newSelectedFiles = newAcceptedFiles.map((file) => {
          return {
            file: file,
            name: "",
            steps: "",
            thumbnail: "",
            video: "",
            patient_id: "",
            body_region: "",
            difficulty: "",
            starting_position: "",
            goal: "",
            tool: "",
            account_patient_id: "",
          };
        });
        setSelectedFileAttributes([
          ...selectedFileAttributes,
          ...newSelectedFiles,
        ]);
        if (acceptedFiles.length > 0 || validFiles.length > 0) {
          setShowForm(0);
        }
      } else {
        alert(t("general.maxImages2"));
      }
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setShowForm(0);
    } else {
      setShowForm(null);
    }
  }, [acceptedFiles]);

  // Handle multilple file uploads
  const handleFileUploads = async (event) => {
    props.setLoader();
    event.preventDefault();
    event.stopPropagation();
    if (selectedFileAttributes.length == 0) {
      alert("Keine Datei vorhanden");
      props.hideLoader();
      // event.preventDefault();
      // event.stopPropagation();
      return;
    }
    await Promise.all(
      selectedFileAttributes.map(async (selectedFileAttribute) => {
        var video_filename = CryptoJS.SHA256(
          selectedFileAttribute.name + Date.now()
        ).toString();
        var extension = selectedFileAttribute.file.name.match(/\.[^.]+$/)[0];
        try {
          var uploadParams = new FormData();
          uploadParams.append("file", selectedFileAttribute.file);
          uploadParams.append("video", video_filename + extension);
          uploadParams.append("thumbnail", video_filename + ".jpg");
          uploadParams.append("account_id", "");
          if (userData.client_id) {
            uploadParams.append("client_id", userData.client_id);
          } else {
            uploadParams.append("client_id", "");
          }
          uploadParams.append("difficulty", selectedFileAttribute.difficulty);
          uploadParams.append("body_region", selectedFileAttribute.body_region);
          uploadParams.append(
            "starting_position",
            selectedFileAttribute.starting_position
          );
          uploadParams.append("goal", selectedFileAttribute.goal);
          uploadParams.append("name", selectedFileAttribute.name);
          uploadParams.append("locale", "");
          uploadParams.append("steps", selectedFileAttribute.steps);
          uploadParams.append("tool", selectedFileAttribute.tool);
          if (props.patientId && forAllPatients == false) {
            uploadParams.append("patient_id", props.patientId);
          } else {
            uploadParams.append("patient_id", "");
          }
          axios
            .post(uploadUrl, uploadParams)
            .then(function (response) {
              alert("Upload erfolgreich");
              props.onHide();
              props.hideLoader();
            })
            .catch(function (error) {
              alert("Es ist ein Fehler aufgetreten");
              props.onHide();
              props.hideLoader();
            })
            .finally(function () {
              props.hideLoader();
            });
        } catch (err) {}
      })
    );
    event.preventDefault();
    event.stopPropagation();
  };

  const handleOnChangeFilter = async (target, value) => {
    const updatedSelectedFileAttributes = [...selectedFileAttributes];
    updatedSelectedFileAttributes[showForm][target] = value;
    setSelectedFileAttributes(updatedSelectedFileAttributes);
  };

  const files = acceptedFiles.map((file, index) => (
    <div
      className="uploaded-exercise-wrapper"
      key={index}
      onClick={() => setShowForm(index)}
      style={{
        cursor: "pointer",
        backgroundColor:
          showForm === index
            ? "var(--background-light)"
            : "var(--color-graph-1)",
      }}
    >
      {smallMobile ? file.path.substring(0, 3) : file.path.substring(0, 5)}...
      <button
        className="uploaded-exercise-wrapper-btn-remove"
        onClick={() => {
          setAcceptedFiles(acceptedFiles.filter((_, i) => i !== index));
          setSelectedFileAttributes(
            selectedFileAttributes.filter((_, i) => i !== index)
          );
        }}
      >
        X
      </button>
      {/* <button onClick={() => handleUpload(file)}>Upload to S3</button> */}
    </div>
  ));

  const isFileFormValid = (file) => {
    const requiredFields = [
      "name",
      "steps",
      "body_region",
      "starting_position",
      "goal",
      "difficulty",
      "tool",
    ];

    return requiredFields.every((field) => file[field] !== "");
  };

  const isFormValid = () => {
    return selectedFileAttributes.every((file) => isFileFormValid(file));
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleFileUploads}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("exercises.createOwnExercise")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-fileupload">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <section className="container">
                <div
                  style={{
                    cursor: "pointer",
                    height: "200px",
                    background: "#f5f9ea 0% 0% no-repeat padding-box",
                    border: "1px dashed #84bd00",
                    borderRadius: "5px",
                    opacity: "1",
                  }}
                  {...getRootProps({ className: "dropzone" })}
                >
                  <input {...getInputProps()} accept="video/*,image/*" />
                  <p className="react-dropzone-container-text">
                    {t("general.putVideoHere")}
                  </p>
                </div>
                <aside>
                  <ul>{files}</ul>
                </aside>
              </section>
            </Form.Group>
            {selectedFileAttributes[showForm] && (
              <>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    autoFocus
                    name="name"
                    value={selectedFileAttributes[showForm].name}
                    placeholder="Übungsname"
                    required
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.value)
                    }
                  ></Form.Control>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="steps"
                    value={selectedFileAttributes[showForm].steps}
                    placeholder="Beschreibung"
                    required
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.value)
                    }
                  ></Form.Control>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <select
                    name="body_region"
                    value={selectedFileAttributes[showForm].body_region}
                    style={{ width: "100%" }}
                    required
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.value)
                    }
                    className="select-box sidebar-filter-elem"
                  >
                    <option style={{ display: "none" }} defaultValue>
                      {t("exercises.body-region")}
                    </option>
                    <option value="CORE">CORE</option>
                    <option value="cervical-spine">Hws</option>
                    <option value="thoracic-spine">Bws</option>
                    <option value="lumbar-spine">Lws</option>
                    <option value="UPPER-LIMB">OBERE EXTREMITÄT</option>
                    <option value="shoulder">Schulter</option>
                    <option value="elbow">Ellbogen</option>
                    <option value="hand">Hand</option>
                    <option value="LOWER-LIMB">UNTERE EXTREMITÄT</option>
                    <option value="hip">Hüfte</option>
                    <option value="knee">Knie</option>
                    <option value="foot">Fuß</option>
                    <option value="legs">Beine</option>
                    <option value="arms">Arme</option>
                    <option value="chest">Brust</option>
                    <option value="body">Ganzkörper</option>
                    <option value="abductor">Abduktoren</option>
                    <option value="adductor">Adduktoren</option>
                    <option value="back">Rücken</option>
                    <option value="biceps">Bizeps</option>
                    <option value="triceps">Trizeps</option>
                    <option value="lungs">Lunge</option>
                  </select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <select
                    name="starting_position"
                    value={selectedFileAttributes[showForm].starting_position}
                    required
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.value)
                    }
                    className="select-box sidebar-filter-elem"
                    style={{ width: "100%" }}
                  >
                    <option style={{ display: "none" }} defaultValue>
                      {t("exercises.position")}
                    </option>
                    <option value="lying">liegend</option>
                    <option value="sitting">sitzend</option>
                    <option value="all-fours">Vierfüßler</option>
                    <option value="standing">stehend</option>
                  </select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <select
                    name="goal"
                    value={selectedFileAttributes[showForm].goal}
                    required
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.value)
                    }
                    className="select-box sidebar-filter-elem"
                    style={{ width: "100%" }}
                  >
                    <option style={{ display: "none" }} defaultValue>
                      {t("exercises.goal")}
                    </option>
                    <option value="all">alle</option>
                    <option value="stabilisation/strength">
                      Stabilisation/Kraft
                    </option>
                    <option value="mobilisation">Mobilisation</option>
                    <option value="coordination">Koordination</option>
                    <option value="stretch">Dehnung</option>
                    <option value="endurance">Ausdauer</option>
                    <option value="neurodynamic">Neurodynamik</option>
                  </select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <select
                    name="difficulty"
                    value={selectedFileAttributes[showForm].difficulty}
                    required
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.value)
                    }
                    className="select-box sidebar-filter-elem"
                    style={{ width: "100%" }}
                  >
                    <option style={{ display: "none" }} defaultValue>
                      {t("exercises.difficulty")}
                    </option>
                    <option value="all">alle</option>
                    <option value="easy">leicht</option>
                    <option value="medium">mittel</option>
                    <option value="hard">schwierig</option>
                  </select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <select
                    name="tool"
                    value={selectedFileAttributes[showForm].tool}
                    required
                    onChange={(e) =>
                      handleOnChangeFilter(e.target.name, e.target.value)
                    }
                    className="select-box sidebar-filter-elem"
                    style={{ width: "100%" }}
                  >
                    <option style={{ display: "none" }} defaultValue>
                      {t("exercises.tool")}
                    </option>
                    <option value="all">alle</option>
                    <option value="kettle">Kettleball</option>
                    <option value="blackroll">Blackroll</option>
                    <option value="theraband">Theraband</option>
                    <option value="dumbbell">Kurzhantel</option>
                    <option value="barbell">Langhantel</option>
                    <option value="pezziball">Pezziball</option>
                    <option value="others">Sonstiges</option>
                    <option value="none">Kein Hilfsmittel</option>
                  </select>
                </Form.Group>
                {props.patientId && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <center>Für alle Patienten freigeben?</center>
                    <input
                      type="checkbox"
                      name="patient"
                      checked={forAllPatients}
                      onChange={(e) => setForAllPatients(!forAllPatients)}
                      className="select-box sidebar-filter-elem"
                      style={{ width: "100%" }}
                    ></input>
                  </Form.Group>
                )}
              </>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={`bs-button-color ${
              !isFormValid() ? "btn-disabled" : ""
            }`}
            type="submit"
            disabled={!isFormValid()}
          >
            {t("profile.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function ExerciseModal(props) {
  const { t } = useTranslation();
  let removeAction = null;
  const [originalValues, setOriginalValues] = useState({});
  const storedUserData = localStorage.getItem("userData");
  const userData = storedUserData ? JSON.parse(storedUserData) : null;
  const filteredDifficultyOptions = difficultyOptions(t).filter(
    (option) => option.value !== "all"
  );
  const filteredBodyRegionOptions = bodyRegionOptions(t).filter(
    (option) => option.value !== "all"
  );
  const filteredStartingPositionOptions = positionOptions(t).filter(
    (option) => option.value !== "all"
  );
  const filteredGoalOptions = goalFilterOptions(t).filter(
    (option) => option.value !== "all"
  );
  const filteredToolOptions = toolOptions(t).filter(
    (option) => option.value !== "all"
  );
  const [titleInputValue, setTitleInputValue] = useState();

  useEffect(() => {
    setTitleInputValue(
      props.exercise?.mapping_sources_translation[0]?.name ||
        props.exerciseData.title
    );
  }, [props.exercise]);

  const [exercise, setExercise] = useState({
    title: "",
    steps: "",
    thumbnail: "",
    video: "",
    patient_id: "",
    body_region: "",
    difficulty: "",
    starting_position: "",
    goal: "",
    tool: "",
  });

  const [newInputEntered, setNewInputEntered] = useState({
    title: false,
    steps: false,
  });

  removeAction = (
    <Button
      type="danger"
      className="delete-button"
      onClick={(e) =>
        handleRemoveExerciseAction(
          e,
          props.exercise,
          props.exerciseData,
          props.onHide
        )
      }
    >
      <DeleteIcon />
    </Button>
  );

  if (typeof props.exercise !== "undefined" && props.show == true) {
    // setExercise({
    //   ...exercise,
    //   title: props.exercise.source_translations[0].name,
    // });
  } else {
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    var params = new FormData();
    let url = "";
    params.append("source_id", props.exercise.id);
    params.append("title", props.exerciseData.title);
    params.append("steps", props.exerciseData.steps);
    params.append("body_region", props.exerciseData.body_region);
    params.append("difficulty", props.exerciseData.difficulty);
    params.append("starting_position", props.exerciseData.starting_position);
    params.append("goal", props.exerciseData.goal);
    params.append("tool", props.exerciseData.tool);
    if (
      props.exercise?.account_id === userData.id ||
      (props.exercise?.client_id &&
        props.exercise?.client_id === userData.client_id) ||
      !props.exercise
    ) {
      url =
        process.env.REACT_APP_SOPHYAPP_API_URL +
        "/de/react/" +
        getAccessToken() +
        "/sources/edit_source";
    } else {
      url =
        process.env.REACT_APP_SOPHYAPP_API_URL +
        "/de/react/" +
        getAccessToken() +
        "/sources/edit_source_mapping";
    }
    axios
      .post(url, params)
      .then(function (response) {
        // use mui/bs alerts
        alert("Erfolgreich bearbeitet!");
        window.location.reload();
      })
      .catch(function (error) {
        alert("Etwas ging schief");
      });
    props.onHide();
  };

  const loadDefaultDescription = () => {
    setOriginalValues({
      ...originalValues,
      [props.exercise.id]: !originalValues[props.exercise.id],
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    props.handleOnChangeModal(name, value);
    // setTitleInputValue(value);
    setNewInputEntered((prevState) => ({
      ...prevState,
      [name]: true,
    }));
    setOriginalValues({
      ...originalValues,
      [props.exercise.id]: true,
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("exercises.edit")}{" "}
            {props.exercise?.account_id === userData.id ||
            (userData.client_id &&
              props.exercise?.client_id === userData.client_id)
              ? removeAction
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                autoFocus
                name="title"
                value={
                  props.exercise?.mapping_sources_translation[0]?.name &&
                  originalValues[props.exercise.id] !== true
                    ? props.exercise.mapping_sources_translation[0].name
                    : props.exerciseData.title
                }
                onChange={handleInput}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="textarea"
                rows={4}
                name="steps"
                value={
                  props.exercise?.mapping_sources_translation[0]?.steps &&
                  originalValues[props.exercise.id] !== true
                    ? props.exercise.mapping_sources_translation[0].steps
                    : props.exerciseData.steps
                }
                onChange={handleInput}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="select"
                name="difficulty"
                value={props.exerciseData.difficulty}
                onChange={(e) =>
                  props.handleOnChangeModal(e.target.name, e.target.value)
                }
                disabled={
                  !(
                    props.exercise?.account_id === userData.id ||
                    (userData.client_id &&
                      props.exercise?.client_id === userData.client_id)
                  ) || !props.exercise
                }
              >
                {filteredDifficultyOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="select"
                name="body_region"
                value={props.exerciseData.body_region}
                onChange={(e) =>
                  props.handleOnChangeModal(e.target.name, e.target.value)
                }
                disabled={
                  !(
                    props.exercise?.account_id === userData.id ||
                    (userData.client_id &&
                      props.exercise?.client_id === userData.client_id)
                  ) || !props.exercise
                }
              >
                {filteredBodyRegionOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="select"
                name="starting_position"
                value={props.exerciseData.starting_position}
                onChange={(e) =>
                  props.handleOnChangeModal(e.target.name, e.target.value)
                }
                disabled={
                  !(
                    props.exercise?.account_id === userData.id ||
                    (userData.client_id &&
                      props.exercise?.client_id === userData.client_id)
                  ) || !props.exercise
                }
              >
                {filteredStartingPositionOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="select"
                name="goal"
                value={props.exerciseData.goal}
                onChange={(e) =>
                  props.handleOnChangeModal(e.target.name, e.target.value)
                }
                disabled={
                  !(
                    props.exercise?.account_id === userData.id ||
                    (userData.client_id &&
                      props.exercise?.client_id === userData.client_id)
                  ) || !props.exercise
                }
              >
                {filteredGoalOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="select"
                name="tool"
                value={props.exerciseData.tool}
                onChange={(e) =>
                  props.handleOnChangeModal(e.target.name, e.target.value)
                }
                disabled={
                  !(
                    props.exercise?.account_id === userData.id ||
                    (userData.client_id &&
                      props.exercise?.client_id === userData.client_id)
                  ) || !props.exercise
                }
              >
                {filteredToolOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {props.exercise?.account_id === userData.id ||
          (userData.client_id &&
            props.exercise?.client_id === userData.client_id) ||
          !props.exercise ? null : (
            <Button
              className="bs-button-color-original"
              onClick={loadDefaultDescription}
            >
              {originalValues[props.exercise.id]
                ? t("profile.customText")
                : newInputEntered["title"] === true ||
                  newInputEntered["steps"] === true
                ? "Neuer Text"
                : t("profile.originalText")}
            </Button>
          )}

          <Button className="bs-button-color" type="submit">
            {t("profile.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function AssignPatientModal(props) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.patients)) {
      const newOptions = props.patients.map((patient) => ({
        value: patient.id,
        label: patient.name,
      }));
      setOptions(newOptions);
    }
  }, [props.patients]);

  const handleSubmit = (event) => {
    let url_appendix = "set_program_for_multiple_patients";
    if (props.template == true) {
      url_appendix = "set_template_as_program_for_multiple_patients";
    }
    let param_arr = [];
    if (selectedOptions && selectedOptions.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      const url =
        process.env.REACT_APP_SOPHYAPP_API_URL +
        "/de/react/" +
        getAccessToken() +
        "/program/" +
        url_appendix;
      var params = new FormData();
      params.append("program_id", props.program);
      selectedOptions.map((patient) => param_arr.push(patient.value));
      params.append("patients", JSON.stringify(param_arr));
      params.append("template", props.template);
      axios
        .post(url, params)
        .then(function (response) {
          if (props.setAssignedSuccess) {
            props.setAssignedSuccess(true);
          }
        })
        .catch(function (error) {
          if (props.setAssignedFailed) {
            props.setAssignedFailed(true);
          }
        });
      props.onHide();
    } else {
      props.onHide();
      event.preventDefault();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("general.assignProgram")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("patients.list")}</Form.Label>
            <Select
              isMulti
              name="patients"
              defaultValue={selectedOptions}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              required
              onChange={setSelectedOptions}
              freeSolo
              createOption={(inputValue) => {}}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("profile.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

//too much requests-> outsource
export function AddFavoritesToGroup(props) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState();
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/settings/get_favorite_groups";
  const url_assigned_groups =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/settings/get_favorite_group_of_exercise";

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        const options = response.data;
        setOptions(options);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    var params = new FormData();
    params.append("source_id", props.exercise.id);
    axios
      .get(url_assigned_groups, {
        params: {
          source_id: props.exercise.id,
        },
      })
      .then((response) => {
        const optSelected = response.data.map((item) => ({
          label: item.group_name,
          value: item.id,
        }));
        setSelectedOptions(optSelected);
      })
      .catch(() => {});
  }, []);

  const handleSubmit = (event) => {
    let param_arr = [];
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/settings/edit_favorite_group";
    var params = new FormData();
    selectedOptions.map((option) => param_arr.push(option));
    params.append("groups", JSON.stringify(param_arr));
    params.append("source_id", props.exercise.id);

    axios
      .post(url, params)
      .then(function (response) {
        //Bootstrap Alert
      })
      .catch(function (error) {
        alert("failed");
      });
    props.onHide();
    event.preventDefault();
  };

  const createOption = (label, id) => ({
    label: label,
    value: id,
  });

  const handleCreateOption = (inputValue) => {
    setIsLoading(true);
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/settings/create_new_fav_group";
    var params = new FormData();
    params.append("name", inputValue);
    axios
      .post(url, params)
      .then(function (response) {
        const updatedOptions = [...options, response.data];
        setIsLoading(false);
        setOptions(updatedOptions);
        const selectedUpdatedOptions = [
          ...selectedOptions,
          createOption(response.data.group_name, response.data.id),
        ];
        setSelectedOptions(selectedUpdatedOptions);
      })
      .catch(function (error) {
        alert("failed");
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("exercises.addToFavorites")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Neue Gruppe erstellen</Form.Label>
            <Form.Control
              placeholder="Favoritengruppe 1"
              name="groupname"
              value={newGroupName}
              autoFocus
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </Form.Group>
          <Modal.Footer>
            <Button
              className="bs-button-color"
              onClick={(e) => createNewGroup()}
            >
              Erstellen
            </Button>
          </Modal.Footer> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("exercises.favoriteGroups")}</Form.Label>
            <CreatableSelect
              isMulti
              isClearable
              formatCreateLabel={(inputValue) => `Erstelle: "${inputValue}"`}
              isDisabled={isLoading}
              isLoading={isLoading}
              name="groups"
              defaultValue={selectedOptions}
              options={options?.map((option) => ({
                label: option.group_name,
                value: option.id,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedOptions}
              value={selectedOptions}
              onCreateOption={(inputValue) => handleCreateOption(inputValue)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("profile.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function AddHashtag(props) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState();
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/settings/get_tags_directly";
  const url_assigned_groups =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/settings/get_tags_of_exercise";

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        const options = response.data;
        setOptions(options);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    var params = new FormData();
    params.append("source_id", props.exercise.id);
    axios
      .get(url_assigned_groups, {
        params: {
          source_id: props.exercise.id,
        },
      })
      .then((response) => {
        const optSelected = response.data.map((item) => ({
          label: item.hashtag,
          value: item.id,
        }));
        setSelectedOptions(optSelected);
      })
      .catch(() => {});
  }, []);

  const handleSubmit = (event) => {
    let param_arr = [];
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/settings/edit_tags";
    var params = new FormData();
    selectedOptions.map((option) => param_arr.push(option));
    params.append("hashtags", JSON.stringify(param_arr));
    params.append("source_id", props.exercise.id);

    axios
      .post(url, params)
      .then(function (response) {
        //Bootstrap Alert
      })
      .catch(function (error) {
        alert("failed");
      });
    props.onHide();
    event.preventDefault();
  };

  const createOption = (label, id) => ({
    label: label,
    value: id,
  });

  const handleCreateOption = (inputValue) => {
    setIsLoading(true);
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/settings/create_new_tag";
    var params = new FormData();
    params.append("name", inputValue);
    params.append("source_id", props.exercise.id);
    axios
      .post(url, params)
      .then(function (response) {
        const updatedOptions = [...options, response.data];
        setIsLoading(false);
        setOptions(updatedOptions);
        const selectedUpdatedOptions = [
          ...selectedOptions,
          createOption(response.data.hashtag, response.data.id),
        ];
        setSelectedOptions(selectedUpdatedOptions);
      })
      .catch(function (error) {
        alert("failed");
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("exercises.addHashtag")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("exercises.hashtag")}</Form.Label>
            <CreatableSelect
              isMulti
              isClearable
              formatCreateLabel={(inputValue) => `Erstelle: "${inputValue}"`}
              isDisabled={isLoading}
              isLoading={isLoading}
              name="groups"
              defaultValue={selectedOptions}
              options={options?.map((option) => ({
                label: option.hashtag,
                value: option.id,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedOptions}
              value={selectedOptions}
              onCreateOption={(inputValue) => handleCreateOption(inputValue)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("profile.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function PatientModal(props) {
  const { t } = useTranslation();
  const { userData } = useContext(AccountContext);
  let clientSet = userData && userData.client_id > 0 ? true : false;
  let removeAction = null;
  let modal_title = t("patients.new-patient");
  if (props.status == "n") {
    modal_title = t("patients.new-patient");
  } else {
    modal_title = t("patients.edit-patient");
    removeAction = (
      <Button
        type="danger"
        className="delete-button"
        onClick={(e) =>
          handleRemoveAction(
            e,
            props.patient.id,
            props.data.account_id,
            props.onHide
          )
        }
      >
        <DeleteIcon />
      </Button>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      createPatient(
        props.patientData,
        props.status,
        props.patient?.id || null,
        clientSet
      );
      props.onHide();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Modal.Title id="contained-modal-title-vcenter">
              {modal_title} {removeAction}
            </Modal.Title>
            <Form.Group
              controlId="formBasicCheckbox"
              style={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Form.Check
                type="checkbox"
                label="Inaktiv"
                checked={props.patientData.archived}
                name="archived"
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  verticalAlign: "middle",
                }}
                onChange={(e) => {
                  props.handleOnChangeModal(e.target.name, e.target.checked);
                }}
              />
            </Form.Group>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
            <Form.Label>{t("profile.title")}</Form.Label>
            <Form.Control
              as="select"
              name="sexuality"
              value={props.patientData.sexuality}
              onChange={(e) => {
                props.handleOnChangeModal(e.target.name, e.target.value);
              }}
            >
              <option value="male">{t("profile.man")}</option>
              <option value="female">{t("profile.woman")}</option>
              <option value="NULL">{t("profile.noGender")}</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("profile.firstname")}</Form.Label>
            <Form.Control
              placeholder="Vorname"
              name="firstname"
              value={props.patientData.firstname}
              autoFocus
              required
              onChange={(e) =>
                props.handleOnChangeModal(e.target.name, e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("profile.lastname")}</Form.Label>
            <Form.Control
              placeholder="Nachname"
              name="lastname"
              value={props.patientData.lastname}
              required
              onChange={(e) =>
                props.handleOnChangeModal(e.target.name, e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("profile.email")}</Form.Label>
            <Form.Control
              type="email"
              name="mail"
              placeholder="user@patient.com"
              value={props.patientData.mail}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              onChange={(e) =>
                props.handleOnChangeModal(e.target.name, e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("profile.diagnosis")}</Form.Label>
            <Form.Control
              name="diagnostic"
              placeholder="Arterielle Hypertonie"
              value={props.patientData.diagnostic}
              onChange={(e) =>
                props.handleOnChangeModal(e.target.name, e.target.value)
              }
            />
          </Form.Group>
          {clientSet && (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("general.location")}</Form.Label>
              <Form.Control
                as="select"
                name="location"
                value={props.patientData.location}
                onChange={(e) =>
                  props.handleOnChangeModal(e.target.name, e.target.value)
                }
              >
                <option value="">Kein Standort</option>
                {props.locations.map((location, index) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("profile.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function ProgramPreviewModal(props) {
  const { t } = useTranslation();
  const { exerciseItems, setExerciseItems, storedUserData } = props;
  let removeAction = null;
  let modal_title = t("program.preview");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Modal.Title id="contained-modal-title-vcenter">
              {modal_title} {removeAction}
            </Modal.Title>
            <Form.Group
              controlId="formBasicCheckbox"
              style={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
              }}
            ></Form.Group>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
          </Form.Group>

          <Row className="list-container-modal">
            {exerciseItems?.length === 0 ? (
              <p>{t("programs.no-exercise-selected")}</p>
            ) : (
              exerciseItems.map((item, index) => (
                <Row className="list-item-wrapper">
                  <Col md={12}>
                    <Row
                      key={index}
                      className="list-item-container-modal"
                      draggable={true}
                      onDragStart={(e) => (dragItem.current = index)}
                      onDragEnter={(e) => (dragOverItem.current = index)}
                      onDragEnd={() =>
                        handleSort(
                          dragOverItem,
                          dragItem,
                          exerciseItems,
                          setExerciseItems
                        )
                      }
                      onDragOver={(e) => e.preventDefault()}
                      onTouchStart={(e) => (
                        (dragItem.current = index), handleTouchStart()
                      )}
                      onTouchMove={(e) => handleTouchMove(e, dragOverItem)}
                      onTouchEnd={handleSort(
                        dragOverItem,
                        dragItem,
                        exerciseItems,
                        setExerciseItems
                      )}
                    >
                      {/* <i className="fa fa-bars"></i> */}
                      <Row className="list-item-modal">
                        <Col md={4} className="list-item-thumbnail">
                          <img
                            className="list-item-image-modal"
                            src={
                              process.env.REACT_APP_SOPHYAPP_THUMBS_URL +
                              item.image
                            }
                            alt="exercise thumbnail"
                          />
                        </Col>
                        <Col md={8} className="list-item-content">
                          <Row>
                            <Col md={12} className="list-item-title">
                              {parse(item.name)}
                            </Col>
                          </Row>
                          <Row>
                            {getWidth() >= 1700 ? (
                              <Col
                                xl={10}
                                xxl={8}
                                className="list-item-info"
                                title={
                                  t("programs.reps") +
                                  "/" +
                                  t("programs.break") +
                                  "/" +
                                  t("programs.set")
                                }
                              >
                                {item.reps} / {item.break} / {item.set}{" "}
                              </Col>
                            ) : null}
                            <Col
                              md={4}
                              xl={1}
                              xxl={2}
                              className="list-item-actions-modal"
                            >
                              <img
                                src="/images/pen.svg"
                                className="list-item-action-icon"
                                onClick={() => editExercisePreview(index)}
                                alt="edit"
                              />
                            </Col>
                            <Col
                              md={4}
                              xl={1}
                              xxl={2}
                              className="list-item-actions-modal"
                            >
                              <img
                                src="/images/garbage.svg"
                                className="list-item-action-icon"
                                onClick={(e) =>
                                  removeExerciseFromList(
                                    index,
                                    exerciseItems,
                                    setExerciseItems
                                  )
                                }
                                alt="delete"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="list-item-edit">
                        <Col
                          md={12}
                          className={"edit-exercise-preview2-" + index}
                          style={{ display: "none" }}
                        >
                          <Row>
                            {storedUserData.setting.ex_weight && (
                              <Col>
                                <input
                                  type="text"
                                  name="weight"
                                  value={item.weight}
                                  className="input-edit-prop"
                                  maxlength={4}
                                  onChange={(e) =>
                                    updateProperty(
                                      index,
                                      e.target.name,
                                      e.target.value,
                                      exerciseItems,
                                      setExerciseItems
                                    )
                                  }
                                ></input>
                                <label>KG</label>
                              </Col>
                            )}
                            <Col>
                              <input
                                type="text"
                                name="reps"
                                value={item.reps}
                                className="input-edit-prop"
                                maxlength={4}
                                onChange={(e) =>
                                  updateProperty(
                                    index,
                                    e.target.name,
                                    e.target.value,
                                    exerciseItems,
                                    setExerciseItems
                                  )
                                }
                              ></input>
                              <label
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  changeExerciseType(
                                    index,
                                    exerciseItems,
                                    setExerciseItems
                                  );
                                }}
                              >
                                {item.typeOfExercise}
                              </label>
                            </Col>
                            <Col>
                              <input
                                type="text"
                                name="break"
                                value={item.break}
                                className="input-edit-prop"
                                maxlength={4}
                                onChange={(e) =>
                                  updateProperty(
                                    index,
                                    e.target.name,
                                    e.target.value,
                                    exerciseItems,
                                    setExerciseItems
                                  )
                                }
                              ></input>
                              <label>SEK</label>
                            </Col>
                            <Col>
                              <input
                                type="text"
                                name="set"
                                value={item.set}
                                className="input-edit-prop"
                                maxlength={4}
                                onChange={(e) =>
                                  updateProperty(
                                    index,
                                    e.target.name,
                                    e.target.value,
                                    exerciseItems,
                                    setExerciseItems
                                  )
                                }
                              ></input>
                              <label>SERIE</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <textarea
                                name="note"
                                value={item.note}
                                className="input-edit-prop-note-modal"
                                onChange={(e) => {
                                  updateProperty(
                                    index,
                                    e.target.name,
                                    e.target.value,
                                    exerciseItems,
                                    setExerciseItems
                                  );
                                }}
                                placeholder="Übungsnotiz bzw. kurze Anmerkung"
                              ></textarea>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>{" "}
                  </Col>
                </Row>
              ))
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button className="bs-button-color" type="submit">
            Speichern
          </Button> */}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function ProgramArchiveModal(props) {
  const { t } = useTranslation();
  const [programs, setPrograms] = useState([]);
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/program/archived`;
  // Fetch programs when component mounts
  useEffect(() => {
    if (props.show) {
      axios
        .get(url, {
          params: {
            patient: props.patientId,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          // Assuming the API returns the programs in an array
          const fetchedPrograms = res.data;
          setPrograms(fetchedPrograms);
        })
        .catch((err) => console.error(err));
    }
  }, [props.show, props.patientId]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form>
        <Modal.Header closeButton>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Modal.Title id="contained-modal-title-vcenter">
              Programmarchiv
            </Modal.Title>
            <Form.Group
              controlId="formBasicCheckbox"
              style={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
              }}
            ></Form.Group>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control value={1} type="hidden" name="id" />
            </Form.Group>
            <Col md={12} className="program-card-element">
              {programs.length === 0 ? (
                <p>Keine archivierten Programme verfügbar</p>
              ) : (
                programs.map((program, key) => (
                  <ProgramCard
                    program={program}
                    id={key}
                    key={key}
                    state="p"
                    style={
                      key === programs.length - 1
                        ? { marginBottom: "150px" }
                        : {}
                    }
                  />
                ))
              )}
            </Col>
          </Form.Group>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
