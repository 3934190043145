import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export const TableView = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Benutzer</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              Anzahl Patienten
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              Anzahl Programme
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              Anzahl Sitzungen
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              Anzahl Heruntergeladene Programme
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data[0].map((item, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                scope="row"
                style={{ fontWeight: "bold" }}
              >
                {item.email}
              </TableCell>
              <TableCell>{item.total_patients}</TableCell>
              <TableCell>{item.total_programs}</TableCell>
              <TableCell>{item.total_sessions}</TableCell>
              <TableCell>{item.total_downloads}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
